import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';

export default function ForgetPasswordEmailForm() {
  
    const { responseApi, getSlug, token, setToken } = useApi();
    const [countDownRequest, setCountDownRequest] = useState(0)
    const [countDownInterval, setCountDownInterval] = useState(null)

    const location = useLocation();

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const resData = await handleApiResponse(data);
        // console.log(resData);
    }
    function resetInputs() {
        reset(
            {
                email : "",
                password : ""
            }
        )
    }

    function countDown(params) {
        
    }


    async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi(`rentingUser/forgetPassword?email=${data.email}&slug=${getSlug()}`, "get")
        console.log(result);
        if (result?.message === "success") {
            toast.success("لقد تم إرسال ايميل. من فضلك تأكد من بريدك الإلكتروني", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate("/login")
            // setCountDownInterval(setInterval( () => {
            //     setCountDownRequest((prevValue) => prevValue + 1000);
            // }, 1000));
        } else if (result?.message === "The renting user does not exist. Please check the data") {
            toast.error("لا يوجد حساب لهذا البريد الإلكتروني", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (result?.message === "The required company account does not exist or not active.") {
            toast.error("حساب الشركة المطلوب غير موجود أو غير مفعل.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }
    // useEffect(() => {
    //     if (countDownRequest === 120000) {
    //         // clearInterval(countDownInterval);
    //         setCountDownRequest(0);
    //         // setCountDownInterval(null)
    //     }
    // }, [countDownRequest])
    
    return (
        <>
        <div className='forget-password-confirm-email'>
            <div className='form-container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-5 text-center'>
                        <h3 className='form-title'>إعادة تعيين كلمة السر</h3>
                    </div>
                    <ul>
                        <li class="mb-2">
                            <input className='primary-input' 
                                type="text" 
                                name="email" 
                                placeholder="البريد الالكتروني"
                                {...register("email", { required: true })}
                            />
                            {errors.email && errors.email.type === "required" && (
                            <div className='text-danger'>البريد الالكتروني مطلوب</div>
                            )}
                        </li>
                    </ul>
                    <div>
                        <button className='form-btn' type="submit">تأكيد</button>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="mt-3 fs-6">سيتم ارسال بريد الكتروني علي حسابك</p>
                            {/* <p  className="mt-3 fs-6 text-danger">{countDownInterval ? 120 - countDownRequest/1000 : <></> }</p> */}
                        </div>

                        {/* <p className='mt-3 fs-6'><Link to={"/register"}><span className='ms-1 text-myPrimary'> تسجيل حساب جديد.</span></Link></p> */}
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}
