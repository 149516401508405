import React from 'react'
import RegistrationForm from '../RegistrationForm/RegistrationForm'
import { useNavigate } from 'react-router-dom'

export default function RegistrationPage() {
  const navigate = useNavigate();

  function registrationDirect() {
    if (sessionStorage.getItem("isPaymentStop")) {
      sessionStorage.removeItem("isPaymentStop");
      navigate("/payment-invoice");
    }else {
      navigate("/")
    }
  }
  return (
    <>
    <div className='registration-page'>
      <RegistrationForm redirectRegistrationSuccess={registrationDirect} />
    </div>
    </>
  )
}
