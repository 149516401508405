import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import crypto from "crypto-js";
import { useApi } from '../Store/ApiContext';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessPaidMessagePopup from '../SuccessPaidMessagePopup/SuccessPaidMessagePopup';
import ErrorPaidMessagePopup from '../ErrorPaidMessagePopup/ErrorPaidMessagePopup';

export default function ConfirmPayment({getUnitData}) {

  const {responseApi, token, decodeToken, postDataWithAuth, getSlug} = useApi();
  const location = useLocation()
  const navigate = useNavigate()

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

    // const apiKey = "1e9da56c-22c4-4e80-ab81-ea608321c6dd";

    function getQuires() {
      const queryParams = new URLSearchParams(location.search);
      const queries = {
        paymentStatus: queryParams.get('paymentStatus'),
        cardDataToken: queryParams.get('cardDataToken'),
        maskedCard: queryParams.get('maskedCard'),
        merchantOrderId: queryParams.get('merchantOrderId'),
        orderId: queryParams.get('orderId'),
        cardBrand: queryParams.get('cardBrand'),
        orderReference: queryParams.get('orderReference'),
        transactionId: queryParams.get('transactionId'),
        amount: queryParams.get('amount'),
        currency: queryParams.get("currency"),
        mode: queryParams.get('mode'),
        signature: queryParams.get('signature'),
      };
      return queries;
    }

    // function validateSignature(query, secret) {
    //   let queryString = '';
    //   for (let key in query) {
    //       console.log(key);
    //     if (key == 'signature' || key == 'mode') continue;
    //     queryString = queryString + '&' + key + '=' + query[key];
    //   }
    //   let finalUrl = queryString.substr(1);
    //   // Creating HMAC SHA-256 hash
    //   const signature = crypto.HmacSHA256(finalUrl, secret).toString(crypto.enc.Hex);
      
    //   if (signature == query.signature) return true;
    //   else return false;
    // }

    async function sendData(merchantOrderId) {
      const data = JSON.parse(sessionStorage.getItem("data"))
      console.log(data);
      console.log(location);
      // sessionStorage.clear();
      if (data.orderId === merchantOrderId) {
        const res = await postDataWithAuth(`listing/booking/${getSlug()}`, data);
        console.log(res);
        return res
      }
    }

    async function handlePaymentResponse() {
      if (sessionStorage.getItem("data") && location.search.length > 100) {
        const queryParams = getQuires();
        const {data} = await responseApi("listing/payment/validateSignature", "patch", queryParams)
        if (data && queryParams.paymentStatus === "SUCCESS") {
          navigate('', { replace: true });
          const {message} = await sendData(queryParams.merchantOrderId);
          getUnitData();
          if (message === "success") {
            setSuccessMessage(true);
          }
        } else if (queryParams.paymentStatus === "FAILURE") {
          navigate('', { replace: true });
          setErrorMessage(true);
        }
      }
    }

    useEffect(() => {
      handlePaymentResponse();
    }, []);


    return (
    <>
    {successMessage ? <SuccessPaidMessagePopup /> : <></> }
    {errorMessage ? <ErrorPaidMessagePopup /> : <></> }
    </>
  )
}
