import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SuccessPaidMessagePopup() {
    const {togglePopUp} = useApi();

    const [bookingData, setBookingData] = useState(null);
    const [unitData, setUnitData] = useState(null);
    const [transaction, setTransaction] = useState(null);

    function popupClose(e) {
        if (e.target.classList.contains("containerBox")) {
            togglePopUp("success-paid-message");
        }    
      }

    useEffect(() => {
        setBookingData(JSON.parse(sessionStorage.getItem("bookingData")));
        setUnitData(JSON.parse(sessionStorage.getItem("unitData")));
        setTransaction(JSON.parse(sessionStorage.getItem("transaction")));
        // togglePopUp("success-paid-message");
    }, [])
    
  return (
    <>
    <section className='success-paid-message'>
        <div className='containerBox ' onClick={(e) => popupClose(e)}>
            <div className='lightContainer' >
                <div className='success-paid-message-container popup-container'>
                        {unitData && 
                        <div>
                            {/* <div className='text-center mb-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" width={"100px"} viewBox="0 0 1024 1024" version="1.1"><path d="M512 981.333333C252.8 981.333333 42.666667 771.2 42.666667 512S252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333z m-50.432-326.101333L310.613333 504.32a32 32 0 0 0-45.226666 45.226667l174.72 174.762666a32.341333 32.341333 0 0 0 0.341333 0.341334l0.256 0.213333a32 32 0 0 0 50.048-6.144l337.450667-379.605333a32 32 0 1 0-47.872-42.496l-318.762667 358.613333z" fill="#52C41A"/></svg>
                            </div> */}
                            <div className='text-center mb-3 '>
                                <FontAwesomeIcon className='success-icon' icon={faCircleCheck} />
                            </div>
                            
                            <ul className='text-center'>
                                <li>
                                    <h5 className='mb-3'>تم الحجز بنجاح <span>🎉</span></h5>
                                    <h6 className='mb-3'>{unitData.unitName}</h6>
                            </li>
                                <li>
                                    <h6>من فترة {moment(bookingData.reqFrom).format("L")} الي {moment(bookingData.reqTo).format("L")} </h6>
                                </li>
                                <li>
                                    <h6 className='ms-2'> <span className='ms-2'>موعد الاستلام 12 - 2 م</span>&nbsp;<span>موعد المغادرة   10 - 12 ص</span></h6>
                                </li>
                                <li>
                                    <h6>نضمن لك تجربة ممتعة <span className='smile-icon'>😉</span></h6>
                                </li>
                            </ul>
                        
                        </div>}
                </div>
            </div>
        </div>
    </section>

    </>
  )
}
