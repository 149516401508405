import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import React, { useEffect } from 'react'
import { Navigation, Pagination, Autoplay } from 'swiper';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
  
export default function PictureSlider({data}) {


const galleryID = "large-gallery"
const widthPhoto = 800
const heightPhoto = 600

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
          gallery: '#' + galleryID,
          children: 'a',
          initialZoomLevel: "fit",
          secondaryZoomLevel: 1.5,
          maxZoomLevel: 1,
          pswpModule: () => import('photoswipe'),
        });
        lightbox.init();
    
        return () => {
          lightbox.destroy();
          lightbox = null;
        };
      }, []);


return (
    <>

    {/* Desktop */}
    <div className='row g-4 image-unit d-none d-md-flex' id={galleryID}>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
            dir="rtl"
            loop={true}
            navigation={ 
            true
        }
            // onNavigationNext={".swiper-button-next"}
            pagination={{
            clickable: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
            style={{
                "--swiper-navigation-color": "#333333",
                "--swiper-pagination-color": "#074acc",
            }}
        >
            {data.unit?.media.map((ele, i, media) => {
                if (i % 5 === 0) {
                    return <>
                    <SwiperSlide key={i}>
                        <div className='row g-4 d-none d-md-flex pb-4' >
                            <div className="col-6 d-none d-md-inline-block">
                                <a
                                    href={media[i]}
                                    data-pswp-width={widthPhoto}
                                    data-pswp-height={heightPhoto}
                                    key={galleryID + '-' + i}
                                    target="_blank"
                                    rel="noreferrer"
                                    className='w-100'
                                    >
                                    <img className='w-100 resize-main-image border-img' src={media[i]}   alt="units" />
                                </a>
                            </div>
                            <div className="col-3">
                                <a
                                    href={media[i + 1]}
                                    data-pswp-width={widthPhoto}
                                    data-pswp-height={heightPhoto}
                                    key={galleryID + '-' + (i + 1)}
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img className='w-100 resize-second-image border-img mb-4'  src={media[i + 1] || media[0]} alt="unit" />
                                </a>
                                <a
                                    href={media[i + 2]}
                                    data-pswp-width={widthPhoto}
                                    data-pswp-height={heightPhoto}
                                    key={galleryID + '-' + (i + 2)}
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img className='w-100 resize-second-image border-img' src={media[i + 2] || media[1] || media[0]} alt="unit" />
                                </a>
                            </div>
                            <div className="col-3">
                            <a
                                    href={media[i + 3]}
                                    data-pswp-width={widthPhoto}
                                    data-pswp-height={heightPhoto}
                                    key={galleryID + '-' + (i + 3)}
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img className='w-100 resize-second-image border-img mb-4'  src={media[i + 3] || media[2] || media[1] || media[0] } alt="unit" />
                                </a>
                                <a
                                    href={media[i + 4]}
                                    data-pswp-width={widthPhoto}
                                    data-pswp-height={heightPhoto}
                                    key={galleryID + '-' + (i + 4)}
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    <img className='w-100 resize-second-image border-img'  src={media[i + 4] || media[3] || media[2] || media[1] || media[0]} alt="unit"/>
                                </a>
                            </div>
                        </div>                        
                    </SwiperSlide>
                    </>
                }
                
            })}
        </Swiper>
    </div>

    {/* MOBILE */}
    <div className="d-md-none" id={galleryID}>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            dir="rtl"
            autoplay={{
                delay: 10000,
                disableOnInteraction: false,
            }}
            loop={true}
            navigation={true}
            pagination={{
            clickable: true,
            }}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
            style={{
                "--swiper-navigation-color": "#000",
                "--swiper-pagination-color": "#074acc",
              }}
        >
            {data.unit?.media.map((ele, i) => {
                return <>
                    <SwiperSlide key={i} >
                        <div className='h-100 pb-4' >
                        <a
                            href={ele}
                            data-pswp-width={widthPhoto}
                            data-pswp-height={heightPhoto}
                            key={galleryID + '-' + i}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <img className='w-100 border-img' src={ele}  width={400} height={424} alt="unit" />
                            {/* <img className='w-25' src={media[i]} alt="" /> */}
                                </a>
                        </div>                      
                    </SwiperSlide>
                    </>
            })}
        </Swiper>
    </div>
  </>
)
}
  