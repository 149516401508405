import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select';
import { useApi } from '../Store/ApiContext';
import makeAnimated from 'react-select/animated';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { t } from 'i18next'


export default function FiltrationPopup({setData, togglePopup, setPopupClose}) {

  const {getData, getSlug} = useApi();
  const navigate = useNavigate()
  const location = useLocation()
  const history = createBrowserHistory()

  // const unitTypeOptions = [
  //   { value: 'Chalet', label: 'شالية' },
  //   { value: 'Villa', label: 'فيلا' },
  //   { value: "Residential Apartment", label: "شقة سكنية" },
  //   { value: "Tourist Apartment", label: "شقة سياحية" },
  // ];
  // const servicesOptions = [
  //   { value : "Beach view", label: 'مطلة علي الشاطئ' },
  //   { value : "Mountain view", label: 'مطلة علي جبل' },
  //   { value : "Lake view", label: 'مطلة علي بحيرة' },
  //   { value : "Sea View", label: 'مطلة علي البحر' },
  //   { value : "Overlooking pool", label: 'مطلة علي مسبح' },
  //   { value : "stove", label: 'بوتجاز' },
  //   { value : "office", label: 'مكتب' },
  //   { value : "Garage", label: 'جراج' },
  //   { value : "heater", label: 'سخان' },
  // ];

  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues : {
      unitType : "",
      services : []
    }
  });
  
  const animatedComponents = makeAnimated();

  async function getFiltration(myData) {
    const slug = getSlug()
    const country = new URLSearchParams(history.location.search).get("country");
    let servicesQuery = ''
    myData.services.map((ele) => {
      servicesQuery += `services[]=${ele.value}&`
    });
    let data = await getData(`filter/${slug}?${servicesQuery ? servicesQuery : 'services[]=&'}unitType=${myData.unitType?.value ? myData.unitType?.value  : ""}&country=${country ? country : '' }`);
    console.log(location);
    if (location.pathname !== "/") {
      togglePopup()
      sessionStorage.setItem("listingData", JSON.stringify(data))
      navigate('/')
    }else {
      setData(data);
      togglePopup();
    }
    console.log(data);
  }

  const onSubmit = myData => {
    console.log(myData)
    getFiltration(myData)
  };
  
  function popupClose(e) {
    if (e.target.classList.contains("containerBox")) {
      togglePopup()
    }    
  }

  async function getServicesAndUnitsTypes() {
    const slug = getSlug();
    let data = await getData(`/servicesAndUnitTypes/${slug}`);
    data.services.forEach((ele, i) => {
      data.services[i] = {value: ele, label: t(ele)}; 
    });
    data.unitTypes.forEach((ele, i) => {
      data.unitTypes[i] = {value: ele, label: t(ele)}; 
    });
    console.log(data);
    setUnitTypeOptions(data.unitTypes);
    setServicesOptions(data.services);
  }

  useEffect(() => {
    getServicesAndUnitsTypes();
  }, [])

  return (<>
      <div  className='containerBox ' onClick={(e) =>  popupClose(e)}>
        <div className='lightContainer' >

          <div className='popup-container filter-container animate__animated animate__faster'>
            <form onSubmit={handleSubmit(onSubmit)} >
              <div>
                <h5>تصفية النتائج بناءا على:</h5>
              </div>
              <ul className=' row'>
                <li className="col-12">
                  <div className='p-3 '>
                      <Controller
                        name="unitType"
                        control={control}
                        render={({ field }) => <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#074acc' : '#D3DFF7',
                          }),
                        }}
                        isClearable={true}
                        placeholder="نوع الوحدة"
                        options={unitTypeOptions}
                        { ...field }
                        />}
                      />
                  </div>
                </li>
                <li className="col-12">
                  <div className='p-3 '>
                      <Controller
                        name="services"
                        control={control}
                        render={({ field }) => <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#074acc' : '#D3DFF7',
                          }),
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder="الخدمات"
                        options={servicesOptions}
                        { ...field }
                        />}
                      />
                  </div>
                </li>
              </ul>
              <div className='text-start mt-2'>
                <button className='primary-small-button' type='submit'>تصفية</button>
              </div>
            </form>
          </div>
        </div>
      </div>
  </>
    
  )
}
