import axios from 'axios';
import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import  $ from 'jquery';

const apiContext = createContext(0)

export default function ApiProvider(props) {

  const navigation = useNavigate()

  const [token, setToken] = useState(decodeToken());

        
  function getSlug() {
    const domainRegex = /\.egarhub\.com$/;
    let slug =  window.location.hostname;
    if (domainRegex.test(slug)) {
      slug = slug.split(".")[0];
    }
    return slug;
  }

  function decodeToken() {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000 || !decoded.exp) {
        localStorage.clear();
        navigation("/login");
      }
      return decoded;
    }
  }
  
    // get data
  async function getData(endPoint) {
    const {data} = await axios({
        method : "get",
        url : `${props.baseUrl}listing/${endPoint}`,
        headers : {
          'Content-Type': 'application/json', 
        }
    });
    console.log(data);
    return data.data;
  }

  async function  postData(endPoint, myData) {
    const {data} = await axios({
        method : "post",
        url : `${props.baseUrl}listing/booking/${endPoint}`,
        headers : {
          'Content-Type': 'application/json'
        },
        data : myData
    })
    console.log(data);
    return data
  }

  async function  postDataWithAuth(endPoint, myData) {
    const {data} = await axios({
        method : "post",
        url : `${props.baseUrl}${endPoint}`,
        headers : {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem("token")}`
        },
        data : myData
    })
    console.log(data);
    return data
  }

  async function responseApi(endPoint="", method, data) {
    const res = await axios({
        url : `${props.baseUrl}${endPoint}`,
        method,
        headers : {
          'Content-Type': 'application/json', 
        },
        data,
    })
    console.log(res);
    if (res.data) {
      return res.data;
    }
  }

  function togglePopUp(className) {
    $(`.${className}`).toggle();
  };  

  return (
    <apiContext.Provider value={{getData, postData, getSlug, responseApi, token, setToken, decodeToken, postDataWithAuth, togglePopUp}}>
        {props.children}
    </apiContext.Provider>
  )
}


export const useApi = () => {
    const context = useContext(apiContext);
    if (context === undefined) {
      throw new Error("useUser must be used within a UserContextProvider.");
    }
    return context;
  };