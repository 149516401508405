import React, { useEffect } from 'react'
import { useApi } from '../Store/ApiContext';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { bookingSuccessMessage, serverErrorMessage, supportMessage, validationErrorMessage } from '../../constants/global';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default function ContactsForm({bookingData, togglePopupContact, oldClientName, setOldClientName, setMobileView}) {

    const {postData, getSlug} = useApi();
    const { handleSubmit, reset, getValues, formState: { errors }, register, control } = useForm({
        defaultValues : {
          clientPhone : "20",
          clientName : ""
        },
        mode: "onChange"
    });
  
    async function validClientContact(myData) {
      const data = {
        clientName : myData.clientName,
        clientPhone : "+" + myData.clientPhone,
        reqFrom : bookingData.reqFrom,
        reqTo : bookingData.reqTo,
        reqGuests : bookingData.reqGuests,
        reqNights : bookingData.reqNights,
        reqTotalCost : bookingData.reqTotalCost,
        unit : bookingData.unit,
      }
      console.log(data);
      const slug = getSlug();
      let res = await postData(`valid/${slug}`, {clientName : data.clientName, clientPhone : data.clientPhone});
      if (res.message === "add new client" || res.message === "result same clientName") {
        const resBooking = await postData(`${slug}`, data);
        if (resBooking.message === "validation error") {
            toast.error(validationErrorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
            typeof(togglePopupContact) === "function" ? togglePopupContact() : setMobileView(false)
        } else if (resBooking.message === "success") {
          toast.success(<div>{bookingSuccessMessage}<br />{supportMessage}</div>, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            rtl : true
          });
          typeof(togglePopupContact) === "function" ? togglePopupContact() : setMobileView(false)
          reset()
        }
      }else if (res.message === "result not same clientName") {
        setOldClientName(res.clientName)
      }
      console.log(res);
    }
  
    async function sendData(isSame) {
      const clientInfo = getValues()
      console.log(clientInfo);
      if (isSame) {
        bookingData.clientName = oldClientName
      }else {
        bookingData.clientName = clientInfo.clientName
      }
      bookingData.clientPhone = "+" + clientInfo.clientPhone
      console.log(bookingData);
      const slug = getSlug();
      const resBooking = await postData(`${slug}`, bookingData);
      console.log(resBooking);
      if (resBooking.message === "success") {
        toast.success(<div>{bookingSuccessMessage}<br />{supportMessage}</div>, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl : true,
        });
        typeof(togglePopupContact) === "function" ? togglePopupContact() : setMobileView(false)
        // reset()
        setOldClientName("")
      } else if (resBooking.message === "validation error") {
        toast.error(validationErrorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl : true,
        });
        } else {
        toast.error(serverErrorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          rtl : true
        });
      }
    }
    
    const onSubmit = myData => {
      console.log(myData);
      if (myData) {
        validClientContact(myData);
      }
    };

    useEffect(() => {
      if (oldClientName === "") {
        reset()
      }
    }, [oldClientName])

      

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} className='mt-3' >
              <div>
                <h5>بيانات التواصل</h5>
              </div>
              <ul className='px-2'>
                {!oldClientName ? <>
                  <li className="">
                  <div className='p-3'>
                    <label htmlFor="clientPhone" className='d-block mb-1'>رقم التليفون</label>
                      {/* <input 
                              type="tel"
                              dir='ltr'
                              id='clientPhone'
                              className='primary-input'
                              placeholder="رقم التليفون" 
                              {...register("clientPhone", {
                                  required: "يرجي ادخال رقم التليفلون",
                                  maxLength: {
                                  value: 14,
                                  message : "يرجي ادخال رقم التليفون بكود المحافظة"
                                  },
                                  minLength: {
                                  value: 14,
                                  message : "يرجي ادخال رقم التليفون بكود المحافظة"
                                  }
                              })}
                          /> */}
                          <div className=' ' >
                            <div className='ltr'>
                              <Controller
                                  name='clientPhone'
                                  control={control}
                                  rules={{
                                    required: "يرجي ادخال رقم التليفلون",
                                    maxLength: {
                                      value: 12,
                                      message : "يرجي ادخال رقم التليفون بكود المحافظة"
                                      },
                                      minLength: {
                                      value: 12,
                                      message : "يرجي ادخال رقم التليفون بكود المحافظة"
                                      }
                                  }}
                                  render={({ field : { ref, ...field } }) =><PhoneInput
                                    {...field}
                                    value={field.value}
                                    onChange={field.onChange}
                                    innerRef={ref}
                                    inputClass='w-100'
                                    searchClass='rtl'
                                    searchPlaceholder={"بحث"}
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                    country={'eg'}
                                  />}
                                />
                            </div>
                            
                          </div>

                          {errors.clientPhone && <p className='text-error'>{errors.clientPhone.message}</p>}
                  </div>
                </li>
                <li className="">
                    <div className='p-3 '>
                        <label htmlFor="clientName" className='d-block mb-1'>الاسم</label>
                        <input 
                            type="text"
                            id='clientName'
                            className='primary-input'
                            placeholder="الاسم بالكامل" 
                            {...register("clientName", {
                                required: " يرجي ادخال الاسم بالكامل",
                                pattern : /^([a-zA-Zأ-يء]+\s[a-zA-Zأ-يء]+){2,}$/,
                            })}
                        />
                        {errors.clientName && <p className='text-error'>{errors.clientName.message}</p>}
                  </div>
                </li></> : <>
                <div>
                  <p>الاسم المسجل لدينا لرقم التليفون هو "{oldClientName}" هل تود تسجيل الطلب بهذا الاسم ام بإستخدام اسم اخر </p>
                </div>
                <li className="">
                    <div className='p-3 '>

                        <label htmlFor="clientName" className='d-block mb-1'>الاسم الاخر</label>
                        <input 
                            type="text"
                            id='clientName'
                            className='primary-input'
                            placeholder="الاسم بالكامل" 
                            {...register("clientName", {
                                required: " يرجي ادخال الاسم بالكامل",
                                pattern : /^([a-zA-Zأ-ي]+\s[a-zA-Zأ-ي]+){2,}$/,

                            })}
                        />
                        {errors.clientName && <p className='text-error'>{errors.clientName.message}</p>}
                  </div>
                </li>
                </>}
              </ul>
              
                {oldClientName ? <>
                <div className='mt-2 text-center contact-btn'>
                  <button className='primary-small-button ms-1' type='button' onClick={() => sendData(true)}>نعم <br />"الاسم المسجل"</button>
                  <button className='secondary-small-button me-1' type='button' onClick={() => sendData(false)}>لا  <br />"الاسم الاخر"</button>
                </div>
                  
                </> : <div className='mt-2 text-start'>
                        <button className='primary-small-button' type='submit'>التالي</button>
                      </div>}
                
              
            </form>
            
    </>
  )
}
