import logoNav from '../../images/LogoSmallColored-nav.png';

export default function Footer() {
  return (
    <footer>
        <a target="_blank" href='https://egarhub.com/' className="d-flex d-xs-inline-block justify-content-between align-items-center px-3 footer">
            <div className='fs-footer'>
                <p>جميع الحقوق محفوظة.</p>
            </div>
            <div className='d-flex align-items-center '>
                <p className='p-2 fs-footer'>© Egarhub</p>
                <div>
                    <img className='w-100 p-2 logo-footer' src={logoNav} alt='egarhub'/>
                </div>
            </div>

        </a>
    </footer>
  )
}
