import React, { useEffect, useState } from 'react'
import {Client, GeocodeRequest} from "@googlemaps/google-maps-services-js";
import Geocode from 'react-geocode';
import Map from '../Map/Map';

Geocode.setApiKey('AIzaSyBgxFNej5RYec_G0S-UzjMmh_ky5Z6iELg');
Geocode.enableDebug();


export default function LocationMap({address}) {
    const [longAddress, setLongAddress] = useState(null);

    const [position, setPosition] = useState({
        lat : "",
        lng : ""
    })


    async function displayLocation(address) {
        try {
          const response = await Geocode.fromAddress(address);
          const center = response.results[0].geometry.location;
          console.log(center);
          console.log(response);
          let positionLocation = {...position};
          positionLocation = {
            lat : center.lat,
            lng : center.lng
          }
          setPosition(positionLocation);
          setLongAddress(response.results[0].address_components[1].long_name)

        } catch (error) {
          console.error(error);
        }
        
      }

    //   const client = new Client({});

    //   client
    //   .elevation({
    //       params: {
    //         // address : "WQMR+G44 El Alamein, Egypt",
    //       locations: [{lat: 30.82247, lng: 28.954309}],
    //       key: "AIzaSyBgxFNej5RYec_G0S-UzjMmh_ky5Z6iELg",
    //       },
    //     //   timeout: 1000, // milliseconds
    //   })
    //   .then((r) => {
    //       console.log(r.data.results[0].elevation);
    //       setResult(response.data);

    //   })
    //   .catch((e) => {
    //     //   console.log(e.response.data.error_message);
    //       console.log(e);
    //   });
    // useEffect(() => {
    //     const client = new Client({ apiKey: apiKey });
    
    //     const geocodeRequest = {
    //       params: {
    //         address: '1600 Amphitheatre Parkway, Mountain View, CA',
    //         key: apiKey,
    //       },
    //     };
    
    //     client.geocode(geocodeRequest)
    //       .then((response) => {
    //         setResult(response.data);
    //       })
    //       .catch((error) => {
    //         if (error.response && error.response.headers['retry-after']) {
    //           const retryAfter = error.response.headers['retry-after'];
    //           setError(`API rate limit exceeded. Retry after ${retryAfter} seconds.`);
    //           console.log(`API rate limit exceeded. Retry after ${retryAfter} seconds.`);
    //         } else {
    //           setError('An error occurred while fetching data from the API.');
    //           console.log('An error occurred while fetching data from the API.');
    //         }
    //       });
    //   }, []);
      useEffect(() => {
        displayLocation(address)
      }, [])
      
  return (
    <>
      <Map center={position}/>
      
    </>
  )
}
