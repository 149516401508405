import { useForm } from 'react-hook-form'
import { useApi } from '../Store/ApiContext';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactsForm from '../ContactsForm/ContactsForm';

export default function ContactsPopup({bookingData, togglePopupContact, oldClientName, setOldClientName, }) {


  

  function popupClose(e) {
    if (e.target.classList.contains("containerBox")) {
      togglePopupContact();
      setOldClientName("")
    }    
  }

  // useEffect(() => {
    // toast.success(<div>تم تقديم طلب الحجز بنجاح<br />يتم التواصل للتأكد</div>, {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    //   rtl : true,
    // });
  // }, []);
    
    
  return (
    <>
     <div  className='containerBox ' onClick={(e) => popupClose(e)}>
        <div className='lightContainer' >
          <div className='contacts-container popup-container animate__animated animate__faster'>
            {oldClientName ? <FontAwesomeIcon className='xmark-contacts-popup cursor-pointer' onClick={() => {togglePopupContact(); setOldClientName("");}} icon={faXmark} /> : <></>}
            <ContactsForm bookingData={bookingData} togglePopupContact={togglePopupContact} setOldClientName={setOldClientName} oldClientName={oldClientName}/>
          </div>
        </div>
      </div>
    </>
  )
}
