import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import translationEng from "./locales/en/translation.json";
import translationAr from "./locales/ar/translation.json";

i18n.use(XHR).init({
  // we init with resources
  resources: {
    en: {
      translations: translationEng,
    },
    ar: {
      translations: translationAr
    },
  },
  lng: "ar",
  fallbackLng: "ar",
  debug: true,
  
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

export default i18n;