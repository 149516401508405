import React, { useEffect, useState } from 'react'
import { faBed, faLocationDot, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from '../Store/ApiContext';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import SliderPictureSmall from '../SliderPictureSmall/SliderPictureSmall';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { t } from 'i18next';

export default function ListingUnits({data, setData, baseUrl}) {

    const {getData, getSlug, responseApi} = useApi();
    
    async function fetchData() {
        const slug = getSlug();
        const data = await getData(slug);
        console.log(data);
        // updateNightCost(data)
        console.log(data);
        setData(data);
    }

    async function getAvailableTimeToday(endPoint) {
        // const {data} = responseApi("availableTime/availableTimeToday", "get")
        // const {data} = await axios({
        //     method : "get",
        //     url : `${baseUrl}availableTime/availableTimeToday`,
        //     headers : {
        //       'Content-Type': 'application/json', 
        //     }
        // });
        console.log(data);
        return data.data;
      }

    async function updateNightCost(data) {
        const availableTimeToday = await getAvailableTimeToday();

        availableTimeToday?.forEach(availableTime => {
            const unit = data.find(u => u._id === availableTime.unit);
            if (unit) {
                unit.cost = availableTimeToday.price
            }
        });
        console.log(availableTimeToday);
        console.log(data);
    }

    useEffect(() => {
        if (sessionStorage.getItem("listingData")) {
            console.log(sessionStorage.getItem("listingData"));
            setData(JSON.parse(sessionStorage.getItem("listingData")))
            sessionStorage.removeItem("listingData")
        }else {
            fetchData()
        }
    }, [])
    
  return (
        <>
            <div className='container'>
                <div className='row g-4 mb-5 pb-5'>
                    {data?.length ? data?.map((ele, i) => {
                        return <div className='col-lg-3 col-md-4 col-sm-6' key={i}>
                            <div className='item-img'>
                                <Link to={`/unit/${ele._id}`}>
                                    <img className=' img-listing' src={ele.media[0]} alt={ele.unitName}/>
                                    {/* <SliderPictureSmall data={ele.media}/> */}
                                </Link>
                            </div>
                            <div className='item-text'>
                                <div className='title-card mt-3'>
                                    <Link to={`/unit/${ele._id}`}>
                                        <h3 className='fs-5 fw-semibold'>{ele.unitName}</h3>
                                        {/* <h3 className='d-xxl-none d-xl-inline-block d-none fs-5 fw-semibold'>{ele.unitName.split(" ").length > 6 ? ele.unitName.split(" ",6).map((ele) => `${ele} ` ) : ele.unitName }</h3>
                                        <h3 className='d-xl-none d-md-inline-block d-none fs-5 fw-semibold'>{ele.unitName.split(" ").length > 4 ? ele.unitName.split(" ",4).map((ele) => `${ele} ` ) : ele.unitName }</h3>
                                        <h3 className='d-md-none d-sm-inline-block d-none fs-5 fw-semibold'>{ele.unitName.split(" ").length > 5 ? ele.unitName.split(" ",5).map((ele) => `${ele} ` ) : ele.unitName }</h3>
                                        <h3 className='d-sm-none d-inline-block fs-5 fw-semibold'>{ele.unitName.split(" ").length > 7 ? ele.unitName.split(" ",7).map((ele) => `${ele} ` ) : ele.unitName }</h3> */}
                                    </Link>
                                    <div className='code-desc text-myMediumGray'>({ele.unitCode})</div>
                                </div>
                                <div className="desc-card">
                                    <ul>
                                        <li className=' fs-14'>
                                            <p className='text-myMediumGray'><FontAwesomeIcon icon={faLocationDot} className='text-myPrimary ps-1' /> <span>{t(ele.country)}</span></p>
                                        </li>
                                        <li className=' fs-14'>
                                            <p className='text-myMediumGray'><FontAwesomeIcon icon={faBed} className='text-myPrimary ps-1' /> عدد الغرف <span>{ele.bedrooms}</span></p>
                                        </li>
                                        <li className=' fs-14'>
                                            <p className='text-myPrimary'><FontAwesomeIcon icon={faMoneyBill} className='text-myPrimary ps-1' /> <span className='text-myMediumGray'>{ele.cost}</span> ج.م / {ele.costInterval === "night" ? "الليلة" : "الشهر"}</p>
                                        </li>
                                    </ul>                                    
                                </div>
                            </div>
                        </div>
                    }) : data === null ? <>
                    {Array.from({length : 8}, (ele, i) => {
                        return (
                        <ContentLoader
                        key={i}
                        rtl
                        width={330}
                        height={350}
                        // viewBox="0 0 330 350"
                        backgroundColor="#f0f0f0"
                        foregroundColor="#dedede"
                        className='col-lg-3 col-md-4 col-sm-6 text-end'
                        // {...props}
                      >
                        <rect x="0" y="243" rx="3" ry="3" width="230" height="10" />
                        <rect x="0" y="243" rx="3" ry="3" width="230" height="10" />
                        <rect x="0" y="263" rx="3" ry="3" width="230" height="10" />
                        <rect x="0" y="243" rx="3" ry="3" width="230" height="10" />
                        <rect x="0" y="220" rx="3" ry="3" width="300" height="15" />
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="206" />
                      </ContentLoader>
                        )
                    })}
                    </> : data?.length === 0 ? <p className='p-3'>لايوجد وحدات لهذه التصفية</p> : <></>}
                </div>

            </div>
            <div className='listing-footer'>
                <Footer/>
            </div>
            
        </>
    )
}
