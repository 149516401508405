import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { useEffect, useState } from 'react';
import ListingUnits from './components/ListingUnits/ListingUnits';
import { ToastContainer } from 'react-toastify';
import PreviewUnit from './components/PreviewUnit/PreviewUnit';
import ApiProvider, { useApi } from './components/Store/ApiContext';
import axios from "axios";

//clearCache
import packageJson from "../package.json";
import withClearCache from './ClearCache';
import RegistrationPage from './components/RegistrationPage/RegistrationPage';
import LoginPage from './components/LoginPage/LoginPage';
import PaymentKashir from './components/PaymentKashir/PaymentKashir';
import ConfirmPayment from './components/ConfirmPayment/ConfirmPayment';
import ForgetPasswordEmailForm from './components/ForgetPasswordConfirmEmailForm/ForgetPasswordConfirmEmailForm';
import SetNewPasswordForm from './components/SetNewPasswordForm/SetNewPasswordForm';
import { t } from 'i18next'

const ClearCacheComponent = withClearCache(App);  
export default function MainApp() {
  return <ClearCacheComponent />;
}

export function App() {
  console.warn = () => {};

  if (process.env.NODE_ENV === 'production') {
    var baseUrl = "https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/";
    console.log = () => {};
  } else {
    var baseUrl = "http://localhost:8080/api/v1/";
  }

  const [data, setData] = useState(null);

  const ProtectedLogin = ({children}) => {
    let token = localStorage.getItem("token");
    console.log(token,"login protect");
    if (!token) {
      return children;
    } else {
      return <Navigate to="/"/>
    }
  }
  

  return (
    <>
    <ApiProvider baseUrl={baseUrl}>
      <Navbar baseUrl={baseUrl} setData={setData}/>
      <div className="main">
        <ToastContainer toastClassName="custom-toast"/>
          <Routes>
            <Route element={<ListingUnits data={data} setData={setData} baseUrl={baseUrl} />} path='/' />
            <Route element={<PreviewUnit baseUrl={baseUrl} />} path='/unit/:id' />
            <Route element={<PaymentKashir baseUrl={baseUrl} />} path='/payment-invoice' />
            <Route element={<ForgetPasswordEmailForm />} path='/forget-password' />
            <Route element={<SetNewPasswordForm />} path='/forget-password/u/:id' />
            <Route element={<ProtectedLogin><RegistrationPage baseUrl={baseUrl} /></ProtectedLogin>} path='/register' />
            <Route element={<ProtectedLogin><LoginPage baseUrl={baseUrl} /></ProtectedLogin>} path='/login' />
          </Routes>
      </div>
    </ApiProvider>
    </>
  );
}