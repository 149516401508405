import { faArrowRightFromBracket, faBars, faMagnifyingGlass, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoNav from '../../images/LogoSmallColored-nav.png';
import $ from 'jquery'
import React, { useEffect, useState } from 'react';
import FiltrationPopup from '../FiltrationPopup/FiltrationPopup';
import SearchNavbar from '../SearchNavbar/SearchNavbar';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../Store/ApiContext';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import CallToActionTopBar from '../CallToActionTopBar/CallToActionTopBar';
import { Dropdown } from 'react-bootstrap';



export default function Navbar({setData, baseUrl}) {

    const location = useLocation()

    const {getData, getSlug} = useApi();
    const [dataInfo, setDataInfo] = useState({})
    
    async function fetchData() {
        const slug = getSlug();
        const data = await getData(slug);
        console.log(data);
        setData(data);
    }
    
    function togglePopup() {
        if ($(".filter-container").hasClass("animate__backInUp")) {
            $(".filter-container").removeClass("animate__backInUp");
            $(".filter-container").addClass("animate__backOutUp");
            $(".filter-popup").delay(500).fadeOut(0);
        } else {
            $(".filter-container").removeClass("animate__backOutUp");
            $(".filter-container").addClass("animate__backInUp");
            $(".filter-popup").fadeIn(0);
        }
    }
    function toggleSearch() {
        if ($(".parent-search").hasClass("animate__fadeInDown")) {
            $(".parent-search").removeClass("animate__fadeInDown");
            $(".parent-search").addClass("animate__fadeOutUp");
            $('.search-navbar').delay(500).fadeOut(0);

        } else {
            $(".parent-search").removeClass("animate__fadeOutUp");
            $(".parent-search").addClass("animate__fadeInDown");
            $(".search-navbar").fadeIn(0);
        }
    }
    function logout(params) {
        localStorage.removeItem("token");
        window.location.reload();
    }
  
    // get data info
  async function getDataInfo() {
    const slug = getSlug();
    const {data} = await axios({
        method : "get",
        url : `${baseUrl}listing/info/${slug}`,
        headers : {
            'Content-Type': 'application/json', 
        }
    })
    console.log(data);
    return data.data
  }

    async function fetchInfoOrgData() {
        const data = await getDataInfo();
        console.log(data);
        setDataInfo(data);
    }

    function ProtectedAuthentication(props) { // shield for login and register
        const logoutElement = <Dropdown.Item >
            <h6 className='m-1 text-end' onClick={() => {logout();}}>تسجيل الخروج</h6>
        </Dropdown.Item> 
        let token = localStorage.getItem("token")
        if (!token) {
            return props.children 
        }
        return logoutElement;
    } 
    

    useEffect(() => {
        fetchInfoOrgData();
    }, []);

    useEffect(() => {
        if (dataInfo?.orgName) {
            document.title = dataInfo?.orgName + " - EgarHub Listing";
        
            //fav.icon

            // let link = document.querySelector("link[rel~='icon']");
            // if (!link) {
            //     link = document.createElement('link');
            //     link.rel = 'icon';
            //     document.getElementsByTagName('head')[0].appendChild(link);
            // }
            // link.href = dataInfo?.orgLogo;
        }
        
    }, [dataInfo])
    

  return (<>
    <nav>
        <div className='d-flex justify-content-between align-items-center navbar px-3'>
            <div className='item-1 logo-navbar cursor-pointer'>
                <Link to={"/"}>
                    {dataInfo?.orgLogo ? <img src={dataInfo?.orgLogo} className='w-100 p-2 logo-navbar' alt='logo'/> : <h1 className='text-myPrimary fw-bold'>{dataInfo?.orgName}</h1> }
                </Link>
            </div>
            <div className='item-2 d-flex align-items-center '>
                <div className='d-flex align-items-center '>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className='icon-search-navbar mx-2 cursor-pointer' onClick={toggleSearch}/>
                    <FontAwesomeIcon icon={faSliders} className=' mx-2 filter cursor-pointer' onClick={togglePopup}/>
                    {/* <a  href={`https://wa.me/${dataInfo?.orgPhone}`} target="blank">
                        <svg width="24" height="24" fill="currentColor" className="bi bi-whatsapp text-myPrimary mx-2" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                        </svg>
                    </a> */}
                </div>
                
                    <div className='me-3'>
                        
                            <div className='profile-menu-dropdown'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className='profile-menu-dropdown-toggle'>
                                            <FontAwesomeIcon className='menu-icon' icon={faBars} />
                                            <svg className='profile-icon' version="1.0" width="35px" height="35px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet" fill='#074acc'>
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                                <path d="M2365 4599 c-705 -68 -1335 -506 -1646 -1144 -483 -994 -87 -2195 893 -2709 783 -410 1735 -271 2376 348 144 139 297 344 389 524 38 72 43 91 43 148 0 78 -15 118 -67 174 -51 58 -102 80 -184 80 -54 0 -77 -5 -120 -28 -58 -30 -83 -62 -183 -236 -27 -47 -53 -86 -56 -86 -4 0 -27 25 -50 55 -71 91 -242 248 -349 320 -55 37 -101 70 -101 74 0 5 10 16 21 27 72 65 181 268 219 409 33 119 39 338 12 465 -112 541 -631 897 -1173 804 -418 -72 -745 -389 -831 -804 -27 -127 -21 -345 12 -465 38 -141 147 -344 219 -409 11 -11 21 -23 21 -27 0 -5 -36 -31 -79 -59 -136 -87 -181 -152 -181 -262 1 -143 107 -250 250 -252 65 -1 103 13 190 72 164 109 325 161 530 169 114 4 152 2 240 -17 172 -35 325 -109 459 -220 65 -55 177 -181 202 -227 12 -22 11 -27 -11 -44 -53 -40 -261 -140 -361 -173 -393 -131 -812 -101 -1175 83 -303 153 -535 385 -686 685 -183 365 -212 783 -82 1175 45 135 137 313 221 424 399 535 1088 752 1721 541 363 -120 670 -377 857 -714 65 -118 87 -144 147 -174 40 -20 67 -26 117 -26 82 0 133 22 184 80 52 56 67 96 67 174 0 57 -5 76 -43 148 -378 736 -1185 1176 -2012 1097z m297 -1283 c95 -21 171 -60 241 -126 217 -203 225 -524 18 -731 -104 -104 -216 -151 -361 -151 -145 0 -257 47 -361 151 -99 100 -149 218 -149 356 0 145 53 268 159 368 75 71 151 112 243 132 82 18 128 18 210 1z"/>
                                                <path d="M4315 2813 c-89 -20 -164 -76 -196 -148 -25 -54 -25 -157 0 -210 62 -133 228 -188 353 -118 179 101 181 346 3 443 -49 28 -121 42 -160 33z"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ProtectedAuthentication>
                                            <Dropdown.Item >
                                                <Link to={"/login"}>
                                                    <h6 className='m-1 text-end'>تسجيل الدخول</h6>
                                                </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <Link to={"/register"}>
                                                    <h6 className='m-1 text-end'>انشاء حساب</h6>
                                                </Link>
                                            </Dropdown.Item>
                                        </ProtectedAuthentication>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {/* <ul className='d-flex align-items-center'>
                                <li>
                                    
                                </li>
                                
                                <li>
                                    <Link to={"/login"}>
                                        <div className='text-myPrimary m-0'>تسجيل الدخول</div>
                                    </Link>
                                </li>
                                <li className='me-3'>
                                    <Link to={"/register"}>
                                        <div className='text-myPrimary m-0'>انشاء حساب</div>
                                    </Link>
                                </li>
                            </ul> */}
                        {/* </ProtectedAuthentication> */}
                    </div>
                

            </div>
            
        </div>
        <div className='search-navbar'>
            <SearchNavbar setData={setData} toggleSearch={toggleSearch}/>
        </div>

        <div className='filter-popup'>
            <FiltrationPopup setData={setData} togglePopup={togglePopup} />
        </div>
    </nav>
  </> 
  )
}
