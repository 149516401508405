const validationErrorMessage = "يرجى إدخال البيانات بشكل صحيح.";
const bookingSuccessMessage = "تم تقديم طلب الحجز بنجاح";
const supportMessage = "سيتم التواصل للتأكيد";
const serverErrorMessage = "حدث خطأ.";
// const constantsErrorMessage = "خطأ";
// const constantsErrorSupportMessage = "برجاء التواصل مع فريق الدعم support@prokfy.com";
// const constantsErrorServerDataMessage = "برجاء التأكد من البيانات او التواصل للمساعدة";
// const percentageValidationMessage = "يجب أدخال نسب مئويه مجموعها 100%"
// const changeSuccess = "تم التغيير بنجاح"
// const changeError = "فشل التغيير"

export {
    validationErrorMessage,
    bookingSuccessMessage,
    supportMessage,
    serverErrorMessage,
    // constantsErrorMessage,
    // constantsErrorSupportMessage,
    // constantsErrorServerDataMessage,
    // percentageValidationMessage,
    // changeSuccess,
    // changeError
}