import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';
import moment from 'moment';

export default function SetNewPasswordForm() {
  
    const { responseApi } = useApi();
    const [initRequest, setInitRequest] = useState(null);

    const location = useLocation();
    const {id} = useParams();

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log(initRequest);
        const initRequestTime = moment(parseInt(initRequest));
        console.log(initRequestTime);
        console.log(moment().diff(initRequestTime, 'hours'));
        const resData = await handleApiResponse(data);

        
    }
    function resetInputs() {
        reset(
            {
                password : "",
                confirmPassword : ""
            }
        )
    }


    async function handleApiResponse(data) {
        const result = await responseApi(`rentingUser/forgetPassword/${id}`, "patch", data);
        console.log(result);
        if (result?.message === "success") {
            resetInputs();
            navigate("/login");
            toast.success("لقد تم تغيير كلمة السر بنجاح", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (result?.message === "The renting user does not exist. Please check the data") {
            toast.error("لا يوجد حساب لهذا البريد الإلكتروني", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (result?.message === "The required company account does not exist or not active.") {
            toast.error("حساب الشركة المطلوب غير موجود أو غير مفعل.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }
    useEffect(() => {
        const initRequestTime = new URLSearchParams(location.search).get("init");
        // setInitRequest(queryParams)
        // console.log(queryParams.get("init"));
        console.log(initRequestTime);
        if (initRequestTime && moment().diff(moment(parseInt(initRequestTime)), 'hours') < 24) {
            navigate('', { replace: true });
        } else {
            navigate("/login")
            toast.error("لقد تم انتهاء صلاحية الرابط", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }, [])
    
    return (
        <>
        <div className='set-new-password'>
            <div className='form-container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-5 text-center'>
                        <h3 className='form-title'>تعيين كلمة السر</h3>
                    </div>
                    <ul>
                        <li class="mb-2">
                            <input className='primary-input' 
                                type="password" 
                                name="password" 
                                placeholder="كلمة السر" 
                                {...register("password", { required: true, pattern : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ })}
                            />
                            {errors.password && errors.password.type === "required" && (
                            <div className='text-danger'>الحد الأدنى ثمانية أحرف، على الأقل حرف واحد، رقم واحد وحرف خاص</div>
                            )}
                            {errors.password && errors.password.type === "pattern" && (
                            <div className='text-danger'>الحد الأدنى ثمانية أحرف، على الأقل حرف واحد، رقم واحد وحرف خاص</div>
                            )}
                        </li>
                        <li class="mb-2">
                            <input className='primary-input' 
                                type="password" 
                                name="confirmPassword"
                                placeholder="تأكيد كلمة السر" 
                                {...register("confirmPassword", { required: true,})}
                            />
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                            <div className='text-danger'>تأكيد كلمة السر مطلوبة</div>
                            )}
                            {!errors.password && watch("password") !== watch("confirmPassword") && (
                            <div className='text-danger'>كلمة السر غير متطابقة</div>
                            )}
                        </li>
                    </ul>
                    <div>
                        <button className='form-btn' type="submit">تأكيد</button>
                        <div className="d-flex justify-content-between align-items-center">
                            {/* <p className="mt-3 fs-6">سيتم ارسال بريد الكتروني علي حسابك</p> */}
                        </div>
                        {/* <p className='mt-3 fs-6'><Link to={"/register"}><span className='ms-1 text-myPrimary'> تسجيل حساب جديد.</span></Link></p> */}
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}
