import React, { useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import crypto from 'crypto-js';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { Nav, Tab, Tabs } from 'react-bootstrap';

export default function PaymentKashir({baseUrl}) {

    const {responseApi, token, decodeToken, postDataWithAuth} = useApi()

    const [order, setOrder] = useState("");
    const [orderId, setOrderId] = useState("");
    const [paymentToken, setPaymentToken] = useState("");
    const [mobileWalletResponse, setMobileWalletResponse] = useState({});

    const [bookingData, setBookingData] = useState(null);
    const [unitData, setUnitData] = useState(null);
    const [transaction, setTransaction] = useState(null);

    const [response, setResponse] = useState({})

        // ** test secret key
    // const secretKey = "f67d0c7aa1f4b93e73ac69aa7c97f4f2$6e6e2888a00f673747078e63d6bbf70e0ff19435ed4c947f03d8b3869362aea5b135f6b57033afe4df42d17baa494190";
        // ** live secret key
    const secretKey = "7523fdff15722cf278bf12c00748de83$977520875967e01e6a2fde6112e481e97ce8bbdc12a7aa27d76efafb4c0248c8b9906eac6215f614e0c7830fb6e9916f";
    const merchantAccountId = "MID-24239-822";

    async function sendRequestKashir(url, options) {
        const response = await fetch(url, options);
        const data = await response.json();
        return data
    }

    async function createInvoiceKashir() {

        const decoded = decodeToken()
        const url = 'https://api.kashier.io/paymentRequest?currency=EGP'
        const options = {
            method: 'POST',
            headers: {accept: 'application/json', 'content-type': 'application/json', "Authorization" : secretKey},
            body: JSON.stringify({
                paymentType: "simple", // simple ===>>> payment request
                merchantId: merchantAccountId,
                customerName: decoded.firstName + " " + decoded.lastName ,
                dueDate: moment().add(1,"h"),
                isSuspendedPayment:true,
                description: "unit renting",
                totalAmount : bookingData.reqTotalCost,
                invoiceItems: [],
                state: "submitted",
                currency: "EGP",
                extraFees: []
            })
        };
        const data = await sendRequestKashir(url, options);
        console.log(data);
        setOrder(data.response);
        return data.response._id;
    }

    async function generateKashirOrderHashAndCreateOrderTransaction() {
        const myData = {
            paymentMethod : "",
            totalPaid : order.totalAmount,
            // commission : bookingData.reqTotalCost * (10/100),
            // fees : 3 + (bookingData.reqTotalCost * (2.75/100)),
            // ownerRevenue : bookingData.reqTotalCost - (bookingData.reqTotalCost * (10/100)) - (3 + (bookingData.reqTotalCost * (2.75/100))),
            currency : order.currency,
            orderId : order._id,
            plan : decodeToken().plan
        }
        const {data} = await postDataWithAuth("listing/payment/orderTransaction", myData);
        console.log(data);
        return data; // This will output the HMAC SHA-256 hash
    }

    function storeBookingData() {
        const data = {
            orderId : order._id, // check order Id

            // Booking Data
            reqFrom : bookingData.reqFrom,
            reqTo : bookingData.reqTo,
            reqGuests : bookingData.reqGuests,
            reqNights : bookingData.reqNights,
            reqTotalCost : bookingData.reqTotalCost,
            unit : bookingData.unit,
    
            // Renting Transaction Data
            paymentMethod : "",
            totalPaid : bookingData.reqTotalCost,
            // commission : bookingData.reqTotalCost * (10/100),
            // fees : 3 + (bookingData.reqTotalCost * (2.75/100)),
            // ownerRevenue : bookingData.reqTotalCost - (bookingData.reqTotalCost * (10/100)) - (3 + (bookingData.reqTotalCost * (2.75/100))),
            currency : order.currency,
        }
        sessionStorage.setItem("data", JSON.stringify(data));
    }

    async function redirectPaymentGateway() {
        storeBookingData()
        const orderHash = await generateKashirOrderHashAndCreateOrderTransaction();
        if (orderHash) {
            window.location.href = `https://checkout.kashier.io/?merchantId=${merchantAccountId}&orderId=${order._id}&amount=${order.totalAmount}&currency=${order.currency}&hash=${orderHash}&mode=live&allowedMethods=card,bank_installments,wallet&merchantRedirect=${window.location.origin}/unit/${bookingData.unit}&failureRedirect=${window.location.origin}/unit/${bookingData.unit}&paymentRequestId=${order.paymentRequestId}&serverWebhook=https://us-central1-prokfy-backend.cloudfunctions.net/app/api/v1/listing/payment/webhookServer&type=external&brandColor=${encodeURIComponent("#074acc")}&display=ar&saveCard=optional&interactionSource=Ecommerce&enable3DS=true`
        } else {
            toast.error("حدث خطأ ما. رجاء اعادة المحاولة", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
        }
    }

    async function handlePaymentKashir() {
        setResponse(null)
        const orderId = await createInvoiceKashir();
        setResponse(orderId)
    }

    useEffect(() => {
        setBookingData(JSON.parse(sessionStorage.getItem("bookingData")));
        setUnitData(JSON.parse(sessionStorage.getItem("unitData")));
        setTransaction(JSON.parse(sessionStorage.getItem("transaction")));
    }, [])

    useEffect(() => {
        if (order) {
            redirectPaymentGateway()
        }
    }, [order])

  return (
    <>
        {bookingData && unitData && transaction && <section className='payment-invoice'>
            <div className='container'>
                <div className=''>
                    <div className='invoice-details mx-auto'>
                        <div className=''>
                            <div className='unit-details '>
                                <h3 className='m-0 mt-2 fw-semibold'>{unitData.unitName}</h3>
                                <h6 className='code-title-unit mb-2'>({unitData.unitCode})</h6>
                                <div className='ms-md-5 mb-2'><FontAwesomeIcon icon={faLocationDot} className='text-myPrimary ps-1' /><span className='text-myMediumGray'>{unitData.country}</span></div>
                                <div className='ms-md-5 mb-2 text-myMediumGray'><FontAwesomeIcon icon={faBed} className='text-myPrimary ps-1' /> عدد الغرف <span>{unitData.bedrooms}</span></div>
                            </div>
                            <hr className='invoice-details-line'/>
                            <div className='request-details '>
                                <ul className='d-flex justify-content-start'>
                                    <li className='ms-4 ps-3 border-start'>
                                        <h6>من</h6>
                                        <p className='fw-semibold'>{moment(bookingData.reqFrom).format("ddd , DD MMM YYYY")}</p>
                                    </li>
                                    <hr className=''/>
                                    <li>
                                        <h6>الي</h6>
                                        <p className='fw-semibold'>{moment(bookingData.reqTo).format("ddd , DD MMM YYYY")}</p>
                                    </li>
                                </ul>
                                
                            </div>
                            <hr className='invoice-details-line'/>
                        </div>
                        <div className='pb-3'>
                            <div className='d-flex justify-content-between  my-3 align-items-center  calc-booking'>
                                <div>
                                    {transaction?.map((trans, i) =>  <p key={i}><span>{trans.nights}</span> ليلة *<span> {unitData.costInterval === "night" ? trans.price : (trans.price / 30.5).toFixed(2)} </span>ج.م</p> )}
                                </div>
                                <div>
                                {transaction?.map((trans, i) => <p key={i}><span>{unitData.costInterval === "night" ? trans.nights * trans.price : (trans.nights * trans.price / 30.5).toFixed(2) }</span> ج.م </p>)}

                                </div>

                            </div>
                            <hr className='invoice-details-line'/>
                            <div className='d-flex justify-content-between  align-items-center my-3'>
                                <p>الإجمالي</p>
                                <p>{bookingData?.reqTotalCost}</p>
                            </div>
                        </div>
                        <button className='primary-medium-button ' onClick={handlePaymentKashir} disabled={response?.message || response ? false : true} >{response?.message || response ? "الدفع" : <Spinner size={"sm"} animation="border" variant="light" /> }</button>
                    </div>
                </div>
            </div>
        </section>}
      
    </>
    
  )
}
