import React, { useState, useEffect } from 'react'
import {  GoogleMap, Autocomplete, useJsApiLoader, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import pin from '../../images/pin.png';
// import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';
// import { BeatLoader } from 'react-spinners';
// import Reload from '../Reload/Reload';
const apiKeyGoogleMaps = 'AIzaSyBgxFNej5RYec_G0S-UzjMmh_ky5Z6iELg';

Geocode.setApiKey(apiKeyGoogleMaps);
Geocode.enableDebug();

const containerStyle = {
  width: '100%',
  height: '500px',
  borderRadius : "20px",
};
const mapOptions = {
  disableDefaultUI: false,
  // styles: [
  //   {
  //     "featureType": "water", // المياه
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#98d0eb"
  //       },
  //       {
  //         "lightness": 17
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "landscape",   // مكعبات السكنية
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#ebe9e9"
  //       },
  //       {
  //         "lightness": 20
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.highway", // الطرق الكبيره 
  //     "elementType": "geometry.fill", 
  //     "stylers": [
  //       {
  //         "color": "#0a2f49"
  //       },
  //       {
  //         "lightness": 17
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.highway", // الطرق الكبير
  //     "elementType": "geometry.stroke", // خطوط
  //     "stylers": [
  //       {
  //         "color": "#d3d3d3"
  //       },
  //       {
  //         "lightness": 29
  //       },
  //       {
  //         "weight": 0.3
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.arterial", // النقط اللي في الماب لما تكبر الزوم
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#ffffff"
  //       },
  //       {
  //         "lightness": 10
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.local", // الشوارع الصغيرة
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#fffffc"
  //       },
  //       {
  //         "lightness": 10
  //       },
  //     ]
  //   },
  //   {
  //     "featureType": "poi",  // حاجات صغيره مش عارفها 
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#f5f5f5"
  //       },
  //       {
  //         "lightness": 21
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.park", // حاجات صغيره مش عارفها  و غير مؤثره
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#dedede"
  //       },
  //       {
  //         "lightness": 21
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "transit", // الخطوط الطرق الصغيره
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#f2f2f2"
  //       },
  //     ]
  //   },
  //   {
  //     "elementType": "labels.text.stroke", // خطوط كلمات العلامات التجارية
  //     "stylers": [
  //       {
  //         "visibility": "on"
  //       },
  //       {
  //         "color": "#ffffff"
  //       },
  //       {
  //         "lightness": 16
  //       }
  //     ]
  //   },
  //   {
  //     "elementType": "labels.text.fill", //  كلمات العلامات التجارية
  //     "stylers": [
  //       {
  //         "saturation": 36
  //       },
  //       {
  //         "color": "#333333"
  //       },
  //       {
  //         "lightness": 40
  //       }
  //     ]
  //   },
  //   {
  //     "elementType": "labels.icon", //   icon العلامات التجارية
  //     "stylers": [
  //       {
  //         "visibility": "on"
  //       },
  //       {
  //         "saturation": 30
  //       },
  //       {
  //         "color": "#cf9400"
  //       },
  //       {
  //         "lightness": 10
  //       }
  //     ]
  //   },
  // ]
}

function Map({center}) {
  console.log(center);

    const centerPosition = {
        lat: center?.lat,
        lng: center?.lng
      };

  const [autoComplete, setAutocomplete] = useState(null)
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10)

  let refMap = null;

  //  ================== S T A R T   M  E  R  G ========================


  const [address, setAddress] = useState('');
  const [markerPosition, setMarkerPosition] = useState(centerPosition);
  const [type, setType] = useState('marker');
  // const saveAddress = (address) => {
  //   // localStorage.setItem('address', address);
  //   handleChange({}, "location", address)
  // };
    
  // useEffect(() => {
  //   if (markerPosition !== centerPosition) {
  //     setZoom(16);
  //     ////("zoom");
  //   }
  // }, [address]);


  // const onMarkerDragEnd = (center) => {
  //   Geocode.fromLatLng(center.lat, center.lng).then(
  //     (response) => {
  //       const address = getAddress(response);
  //       saveAddress(address);
  //       setType('marker');
  //       setAddress(address || '');
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };

  // const accessGeolocation = async () => {
  //   if ('geolocation' in navigator) {
  //     try {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //         const { latitude, longitude } = position.coords;
  //         displayCurrentLocation(latitude, longitude);
  //       });
  //     } catch (error) {
  //       // ////(error);
  //       // console.error(error);
  //     }
  //   }
  // };
  
  // const displayCurrentLocation = async (lat, lng) => {
  //   try {
  //     const response = await Geocode.fromLatLng(lat, lng);
  //     const address = getAddress(response);
  //     ////(response);
  //     saveAddress(address);
  //     const center = {
  //       lat: lat,
  //       lng: lng
  //     }
  //     onMarkerDragEnd(center)
  //     setMarkerPosition(center);
  //     // ////(lat,lng);
  //     setType('current');
  //     setAddress(address || '');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  

  //  ================== E  N  D    M  E  R  G ========================
// const getAddress = ( addressArray ) => {
//   ////(addressArray.plus_code.compound_code);
//     let Address = addressArray.plus_code.compound_code;
    
//     // for( let i = 0; i < addressArray.length; i++ ) {
//     //   if ( addressArray[ i ].types[0] && 'street_address' === addressArray[ i ].types[0] ) {
//     //    Address = addressArray[ i ].formatted_address;
//     //    return Address;
//     //   }else if( addressArray[ i ].types[0] && 'route' === addressArray[ i ].types[0] ) {
//     //     Address = addressArray[ i ].formatted_address;
//     //     return Address;
//     //   }
//     //  }
//     // Address = addressArray[ 0 ].formatted_address;
//     return Address;
//    };
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(centerPosition);
    // map.fitBounds(bounds);

    setMap(map)
  }, [])
        // M A R K E R 
   const loadMarker = marker => {
      console.log("marker:", marker);
      // setMarkDrag(marker)     
  }


  // A U T O  C O M P L E T E
  
  //  function loadAutocomplete (autocomplete) {
  //   //  ////('autocomplete: ', autocomplete)
  //   setAutocomplete(autocomplete)
  // }

  //  function onPlaceChanged () {
  //   // ////("what this");
  //   if (autoComplete !== null) {
  //     const autocompeleteValue = autoComplete.getPlace()
  //      onPlaceSelected(autocompeleteValue)
  //   } else {
  //   //   ////('Autocomplete is not loaded yet!')
  //   }
  // }
  
  // const onPlaceSelected = (place) => {
  //   const address = place.formatted_address;
  //   const latValue = place.geometry.location.lat();
  //   const lngValue = place.geometry.location.lng();
  //   saveAddress(address);
  //   const center = {
  //     lat: latValue,
  //     lng: lngValue
  //   }
  //   // ////(center);
  //   onMarkerDragEnd(center)
  //   setMarkerPosition(center);

  //   setType('autocompelete');
  //   setAddress(address || '');
  // };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  // function handleCenter() {
  //   // ////("hiiiii");
  //   // setType("marker")
  //   if (refMap?.state.map) {
  //     const mapCenter = refMap.state.map.center
  //     const newLat = mapCenter.lat()
  //     const newLng = mapCenter.lng()
  //   //   ////('googleMap2', refMap, mapCenter);
  //     // ////("mapCenter", newLat, newLng);
  //     const center = { 
  //       lat :newLat,
  //       lng : newLng
  //     }
  //     onMarkerDragEnd(center)
  //     setMarkerPosition(center)
  //   }
  // }
  function mapPosition() {
    return centerPosition
  }

const [ libraries ] = useState(['places']);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey : apiKeyGoogleMaps,
    libraries ,
  })

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    return <GoogleMap
    id='aboney-map'
    ref={(ref) => {refMap = ref}}
    mapContainerStyle={containerStyle}
    center={center}
    onLoad={onLoad}
    onUnmount={onUnmount}
    zoom = {12}
    // onCenterChanged={() => {handleCenter();}}
    // options = {mapOptions}
    gestureHandling={"greedy"} // or "cooperative" || "greedy" Set this to "greedy" to enable single-finger panning
  >
    { /* Child components, such as markers, info windows, etc. */ }
    <>
    <Marker
      onLoad={loadMarker}
      position={center}
      icon={"https://firebasestorage.googleapis.com/v0/b/prokfy-admin.appspot.com/o/unitsMedia%2Ftest%2F23213%2Fpin.png?alt=media"}
    />
    </>
    
  </GoogleMap>
  }
  return  (
    <>
      <div>
        <div className='position-relative' style={containerStyle}>
            {/* ============ START MERG ================ */}
          <div className='position-relative d-block '>
            {isLoaded ? renderMap() : <div className=" d-flex align-items-center">loading....</div>}
          </div>
            {/* ============  E  N  D     M  E  R  G  ================ */}
        </div>
      </div>
    </>


  )
}

export default Map;