 

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select'
import { useApi } from '../Store/ApiContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { t } from 'i18next';

export default function SearchNavbar({setData, toggleSearch}) {

    const {getData, getSlug} = useApi();
    const navigate = useNavigate()
    const location = useLocation();
    const history = createBrowserHistory()

    const [countries, setCountries] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const { control, handleSubmit } = useForm({
        defaultValues: {
            country: null
        }
    });
    const onSubmit = myData => getSearch(myData);

    async function getSearch(myData) {
        const country = myData.country ? myData.country?.value : '';
        history.push(`?country=${country}`, 'search');
        const slug = getSlug();
        const data = await getData(`search/${slug}?country=${country ? country : '' }`);
        console.log(location.pathname);
        if (location.pathname !== "/") {
            sessionStorage.setItem("listingData", JSON.stringify(data))
            toggleSearch()
            navigate('/')
        }else {
            setData(data);
            toggleSearch();
        }
    }
    function searchClose(e) {
        if (e.target.classList.contains("layout-search")) {
            toggleSearch()
        }    
    }

    async function getCountriesSearch() {
        const slug = getSlug();
        let data = await getData(`/unitsCountries/${slug}`);
        data.forEach((ele, i) => {
            data[i] = {value: ele, label: t(ele)}; 
        });
        console.log(data);
        setCountries(data)
    }

    useEffect(() => {
        getCountriesSearch()
    }, [])
    


  return (
    <>
        <div className='layout-search' onClick={searchClose}>
            <form onSubmit={handleSubmit(onSubmit)} className='parent-search animate__animated animate__faster'>
                <div className='search'>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field }) => <Select
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                            ...theme.colors,
                            primary25: '#D3DFF7',
                            primary: '#074acc',
                            },
                        })}
                        isClearable={true}
                        options={countries}
                        placeholder="المحافظة"
                        { ...field }
                        />}
                    />
                        <button type='submit' className='parent-icon-search-navbar-old'>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='icon-search-navbar-old'/>
                        </button>
                </div>
            </form> 
        </div>
    </>
  )
}
