import React from 'react'
import LoginForm from '../LoginForm/LoginForm'
import { useNavigate } from 'react-router-dom'

export default function LoginPage() {
  const navigate = useNavigate()

  function loginDirect() {
    if (sessionStorage.getItem("isPaymentStop")) {
      sessionStorage.removeItem("isPaymentStop");
      navigate("/payment-invoice");
    }else {
      navigate("/")
    }
  }

  return (
    <LoginForm redirectLoginSuccess={loginDirect} />
  )
}
