import { faBed, faChevronLeft, faLocationDot, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react";
import { DateRangePicker } from "react-dates"
import ContactsForm from "../ContactsForm/ContactsForm";
import { useNavigate } from "react-router-dom";
import { useApi } from "../Store/ApiContext";
import { toast } from "react-toastify";

export default function MobileBooking({
        bookingData,
        data, 
        dateRange,
        handleChangeDate,
        handleChangePlusGuests,
        handleChangeMinusGuests,
        isOutsideRange,
        isDayBlocked,
        setMobileView,
        oldClientName,
        setOldClientName,
        transaction
    }) {

        const {decodeToken} = useApi()
    const navigate = useNavigate()
    const [focusedInput3, setFocusedInput3] = useState(null);
    const [contactStep, setContactStep] = useState(true);

    function redirectToInvoicePayment() {
        const decoded = decodeToken();
        sessionStorage.setItem("bookingData", JSON.stringify(bookingData));
        sessionStorage.setItem("unitData", JSON.stringify(data.unit));
        sessionStorage.setItem("transaction", JSON.stringify(transaction));
        if (decoded) {
            navigate("/payment-invoice");
        } else {
            sessionStorage.setItem("isPaymentStop", true);
            navigate("/login");
            toast.warning("رجاء تسجيل الدخول قبل الحجز", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });
        }
    }


  return ( <>
    <div className=" secure-desktop-view">
        <div className="text-start fs-5 icon-prev-booking">
            <FontAwesomeIcon icon={faChevronLeft} className="cursor-pointer" onClick={() =>{ setMobileView(false); setOldClientName("");}} />
        </div>
        {/* {contactStep ? <> */}
            <section id='title-unit pt-2' >
                <div className='title-unit'>
                    <h3 className='m-0 mt-2 fw-semibold'>{data.unit.unitName}</h3>
                    <h6 className='code-title-unit'>({data.unit.unitCode})</h6>
                    <ul className="info-unit d-flex justify-content-start">
                        <li className='ms-md-5 ms-4'><FontAwesomeIcon icon={faLocationDot} className='text-myPrimary ps-1' /><span className='text-myMediumGray'>{data.unit.country}</span></li>
                        <li className='ms-md-5 ms-4 text-myMediumGray'><FontAwesomeIcon icon={faBed} className='text-myPrimary ps-1' /> عدد الغرف <span>{data.unit.bedrooms}</span></li>
                    </ul>
                </div>
            </section>
            <hr  className='line-unit w-100'/>
            <section id='booking-unit-mobile' className='booking-unit-mobile mx-auto mb-5' >
                <h5 className='text-myPrimary fw-semibold mb-4'><span>{data.unit.cost} </span>ج.م / <span>{data.unit.costInterval === "night" ? "ليلة" : "شهر"}</span></h5>
                <div className='calender-booking w-100'>
                    <DateRangePicker
                        isRTL={true}
                        startDate={dateRange?.startDate}
                        endDate={dateRange?.endDate}
                        startDateId='startDate'
                        endDateId='endDate'
                        onDatesChange={handleChangeDate}
                        focusedInput={focusedInput3}
                        onFocusChange={(focusedInput) => setFocusedInput3(focusedInput)}
                        startDatePlaceholderText='من'
                        endDatePlaceholderText='الي'
                        displayFormat="YYYY/MM/DD"
                        customArrowIcon={"-"}
                        isOutsideRange={isOutsideRange}     
                        isDayBlocked={isDayBlocked}
                        numberOfMonths={1}
                        hideKeyboardShortcutsPanel
                        reopenPickerOnClearDates
                    />
                </div>
                <div className='individuals-booking d-flex justify-content-between align-items-center'>
                    <div className='py-2'>
                        <h6 className='m-0'>الأفراد</h6>
                        {/* <p className=''><span>{bookingData.reqGuests}</span> فرد</p> */}
                    </div>
                    <div className='d-flex'>
                        <button className='btn-icon-guests'>
                            <FontAwesomeIcon className='icon-booking' onClick={handleChangeMinusGuests} icon={faMinus}/>
                        </button>
                        <div className='mx-3 my-auto mx-2'>
                            {bookingData.reqGuests}
                        </div>
                        <button className='btn-icon-guests'>
                            <FontAwesomeIcon className='icon-booking' onClick={handleChangePlusGuests} icon={faPlus}/>
                        </button>
                    </div>
                </div>
                <div>
                    <button className='booking-button mt-4' onClick={() =>  bookingData.reqNights ? redirectToInvoicePayment() : () => {} } >إحجز</button>
                </div>
                <div className='d-flex justify-content-between mx-3 align-items-center my-3 calc-booking'>
                    {/* <p><span>{bookingData.reqNights}</span> ليالي  * <span>{data.unit.cost}</span> ج.م</p>
                    <p><span>{bookingData.reqTotalCost}</span> ج.م</p> */}
                    <div>
                        {transaction?.map((trans) =>  <p><span>{trans.nights}</span> ليلة *<span> {data.unit.costInterval === "night" ? trans.price : (trans.price / 30.5).toFixed(2)} </span>ج.م</p> )}
                    </div>
                    <div>
                    {transaction?.map((trans) => <p><span>{data.unit.costInterval === "night" ? trans.nights * trans.price : (trans.nights * trans.price / 30.5).toFixed(2) }</span> ج.م </p>)}

                    </div>

                </div>
                <hr className='line-booking'/>
                <div className='d-flex justify-content-between mx-3 align-items-center my-3'>
                    <p>الإجمالي</p>
                    <p>{bookingData.reqTotalCost}</p>
                </div>
                <div className='mx-3'>
                    <p className='myFs-10'><span>*</span>اي مناقشة في السعر تتم من خلال مكالمة تأكيد الحجز بعد اتمام عملية الحجز<span>*</span></p>
                    <p className='myFs-10'><span>*</span>سيتم الإتفاق على مبلغ جديه الحجز و كذلك التأمين خلال الإتصال لتأكيد الحجز<span>*</span></p>
                </div>
            </section>
        {/* </>  : <>
            <ContactsForm bookingData={bookingData} setOldClientName={setOldClientName} oldClientName={oldClientName} setMobileView={setMobileView}/>
            </>} */}
    </div>
   
  </> )
}
