import logo from '../../images/LogoSmallColored-nav.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBath, faBed, faCircleMinus, faCirclePlus, faCouch, faKitchenSet, faLocationDot, faMinus, faMountain, faPlus, faShower, faStar, faToiletPaper, faUmbrellaBeach, faWaterLadder } from '@fortawesome/free-solid-svg-icons';
import {DateRangePicker, DayPickerRangeController} from 'react-dates';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/en-au'
import 'moment/locale/ar-ly'
import LocationMap from '../LocationMap/LocationMap';
import $ from 'jquery'
import { useApi } from '../Store/ApiContext';
import ContentLoader from 'react-content-loader';
import PictureSlider from '../PictureSlider/PictureSlider';
import ContactsPopup from '../ContactsPopup/ContactsPopup';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MobileBooking from '../MobileBooking/MobileBooking';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmPayment from '../ConfirmPayment/ConfirmPayment';
import SigninPopup from '../SigninPopup/SigninPopup';
import {t} from 'i18next';

export default function PreviewUnit({baseUrl}) {
    moment.locale("ar-ly");
    const navigate = useNavigate()
    const params = useParams();
    const {getData, getSlug, decodeToken, togglePopUp} = useApi();

    // const [isSigninPopupOpen, setIsSigninPopupOpen] = useState("")

    const [mobileView, setMobileView] = useState(false)
    const [oldClientName, setOldClientName] = useState("")
    const [focusedInput, setFocusedInput] = useState("startDate");
    const [focusedInput2, setFocusedInput2] = useState(null);
    const [data, setData] = useState({});
    const [servicesHtml, setServicesHtml] = useState([])
    const [showMoreServices, setShowMoreServices] = useState(false);
    const [availableTime, setAvailableTime] = useState(null)
    const [transaction, setTransaction] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate : null,
        endDate : null
    })
    const [bookingData, setBookingData] = useState({
        reqFrom : null,
        reqTo : null,
        reqGuests : 1,
        reqNights : 0,
        reqTotalCost : 0,
        unit : params.id,
      });
    function handleChangeDate(e) {
        console.log(e);
        setDateRange({...e})
    }
    function handleChangePlusGuests() {
        const myData = {...bookingData}
        myData.reqGuests = myData.reqGuests + 1
        setBookingData(myData)
    }
    function handleChangeMinusGuests() {
        if (bookingData.reqGuests > 0) {
            const myData = {...bookingData}
            myData.reqGuests = myData.reqGuests - 1
            setBookingData(myData)
        }
    }
    const handleFocusChange = (focusedInput) => {
        console.log(focusedInput);
        if (focusedInput === null) {
            setFocusedInput("startDate");
        }else {
            console.log(focusedInput);
            setFocusedInput(focusedInput);
        }
    };
    const isOutsideRange = day => {
        console.log();
        return day.isBefore(moment().subtract(1, "day"));
    }
    const isDayBlocked = (day) => {
        let requestsAccepted = data.requests
        for (let i = 0; i < requestsAccepted.length; i++) {
          const { reqFrom, reqTo } = requestsAccepted[i];
          ////(reqFrom, reqTo);
          if (moment(day).isBetween(reqFrom, reqTo, "day")) {
            return true;
          }
        }
        let test = false
        requestsAccepted?.forEach(ele => {
          requestsAccepted?.forEach(ele2 => {
            if (moment(ele.reqFrom).isSame(ele2.reqTo, "day")) {
              if (moment(ele.reqFrom).isSame(moment(day).utc(true).format(), "day")) {
                test = true
              }
            }
          });
        });
        return test;
    }
    function getStartAndEndDatesDefault() {
        if (data?.requests?.length === 0) {
            let startDate =   moment();
            let endDate =  moment(startDate).add(3, "day") ;
            console.log(startDate, endDate);
            setDateRange({startDate, endDate});
        }
    }
    async function getAvailableTimeUnit(startDate, endDate) {
        const slug = getSlug();
        const {data} = await axios({
          method : "get",
          url : `${baseUrl}listing/booking/availableTimeUnit/${slug}/${bookingData.unit}?startDate=${startDate}&endDate=${endDate}`,
          headers : {
            'Content-Type': 'application/json', 
          } ,
        })
        console.log(data);
        if (data?.message === "success") {
          return calcPriceNightsPerAvailableTime(data?.results, {startDate, endDate} )
        }
        // setNewPrice(data.results.allAvailableTime[0].price);
        // setAvailableTime(data.results.allAvailableTime[0].available);
      }
      
    function calcPriceNightsPerAvailableTime(availableTimes, date) {
        console.log(date);
        let diffDaysCollect = 0;
        let diff
        let transaction = [];
        const inputStartDate = moment(date.startDate);
        const inputEndDate = moment(date.endDate);
        availableTimes?.forEach((availableTime, i) => {
          const availableTimeStartDate = moment(availableTime.startDate);
          const availableTimeEndDate = moment(availableTime.endDate);
         
          if (availableTime?.available) {
            setAvailableTime(true)
            if (i === 0 && inputStartDate.isSameOrAfter(availableTimeStartDate)) {
              if (availableTimeEndDate.isAfter(inputEndDate)) {
                diff = inputEndDate.diff(inputStartDate, "days");
              } else {
                diff = availableTimeEndDate.diff(inputStartDate, "days");
              }
              console.log(diff); // 2
              diffDaysCollect += diff
              console.log(availableTime.price);
            } else if (i === (availableTimes.length - 1) && inputEndDate.isSameOrBefore(availableTimeEndDate)) {
                diff = inputEndDate.diff(availableTimeStartDate, "days");
                console.log(diff); // 5
                diffDaysCollect += diff
                console.log(availableTime.price);
            } else {
              diff = availableTimeEndDate.diff(availableTimeStartDate, "days")
              console.log(diff);
              diffDaysCollect += diff;

            }
            transaction.push({nights : diff, price : availableTime.price})
          } else if (availableTime.available === false) {
            setAvailableTime(false)
            return 0 ;
          }
        })
        const allNights = inputEndDate.diff(inputStartDate, "days");
        console.log(allNights);
        console.log(diffDaysCollect);
        const diffDays = allNights - diffDaysCollect
        if (diffDays) {
          console.log("eh");
          transaction.push({nights : diffDays, price : data.unit.cost })
        }
        console.log(transaction);
        setTransaction(transaction);
        return {transaction, allNights}
        // calculateRequestNights(transaction, allNights)
    }

    async function calcBooking(startDate, endDate) {
        if (startDate && endDate) {
            // let nights = endDate.diff(startDate, 'day');
            const {transaction, allNights} = await getAvailableTimeUnit(startDate, endDate)
            console.log(transaction, allNights);
            
            let totalCost = 0;
            // if (data.unit.costInterval === "night") {
            //     totalCost = nights * data.unit.cost;
            // }else if (data.unit.costInterval === "month") {
            //     let months = nights / 30.5;
            //     totalCost = months * data.unit.cost;
            // }
            if (data.unit.costInterval === "night") {
                transaction?.forEach( ele => {
                  totalCost += ele.nights * ele.price
                  console.log(totalCost);
                });
              }else if (data.unit.costInterval === "month") {
                transaction?.forEach(ele => {
                  let months = ele.nights / 30.5
                  totalCost += months * ele.price
                  console.log(totalCost);
                });
              }
            console.log(totalCost);
            let myData = {...bookingData};
            myData.reqNights = allNights;
            myData.reqTotalCost = totalCost.toFixed(2);
            myData.reqFrom = moment(startDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
            myData.reqTo = moment(endDate).utc(true).set({hour:12,minute:0,second:0,millisecond:0}).format();
            console.log(myData);
            setBookingData(myData);
        }
    }

    function togglePopupContact() {
        if ($(".contacts-container").hasClass("animate__backInUp")) {
            $(".contacts-container").removeClass("animate__backInUp");
            $(".contacts-container").addClass("animate__backOutUp");
            $(".contacts-popup").delay(500).fadeOut(0);
        } else {
            $(".contacts-container").removeClass("animate__backOutUp");
            $(".contacts-container").addClass("animate__backInUp");
            $(".contacts-popup").fadeIn(0);
        }
    }
    // function toggleDropdownIndividuals() {
    //     if ($(".dropdown-individuals-input").hasClass("d-none"))
    //     {
    //         $(".dropdown-individuals-input").removeClass("d-none")
    //     }else {
    //         $(".dropdown-individuals-input").addClass("d-none")
    //     }
    // }

    function showServices() {
        let servicesHtml = [] 
        const services = data.unit.services
        for (let i = 0; i < services.length; i++) {
            switch (services[i]) {
                case 'Beach view':
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faUmbrellaBeach} /> <span className='me-1'>{t("Beach view")}</span></li>)
                break;
                case 'Sea View':
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faUmbrellaBeach} /> <span className='me-1'>{t("Sea View")}</span></li>)
                break;
                case "Overlooking pool":
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faUmbrellaBeach} /> <span className='me-1'>{t("Overlooking pool")}</span></li>)
                break;
                case "stove":
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faKitchenSet} /> <span className='me-1'>{t("stove")}</span></li>)
                break;
                case "office":
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faCouch} /> <span className='me-1'>{t("office")}</span></li>)
                break;
                case "heater":
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faShower} /> <span className='me-1'>{t("heater")}</span></li>)
                break;
                case "Mountain view":
                    servicesHtml.push(<li className='my-2'><FontAwesomeIcon icon={faMountain} /> <span className='me-1'>{t("Mountain view")}</span></li>)
                break;
                default:
                    break;
            }
        }
        console.log(servicesHtml);
        setServicesHtml(servicesHtml)
    }

    async function getUnitData() {
        const id = params.id;
        const slug = getSlug();
        const data = await getData(`${slug}/${id}`);
        console.log(data);
        setData(data);
    }

    function smoothScroll(e, id){
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(`${id}`).offset().top - 85
        }, 100);
    };

    function redirectToInvoicePayment() {
        const decoded = decodeToken();
        sessionStorage.setItem("bookingData", JSON.stringify(bookingData));
        sessionStorage.setItem("unitData", JSON.stringify(data.unit));
        sessionStorage.setItem("transaction", JSON.stringify(transaction));
        if (decoded) {
            navigate("/payment-invoice");
        } else {
            sessionStorage.setItem("isPaymentStop", true);
            navigate("/login");
            // if (isSigninPopupOpen) {
            //     togglePopUp("signin-popup")
            // } else {
            //     setIsSigninPopupOpen(true)
            // }
            toast.warning("رجاء تسجيل الدخول قبل الحجز", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl : true
            });

        }
    }
    

    useEffect(() => {
        getUnitData();
    }, [])

    useEffect(() => {
        if (data?.unit) {
            showServices()
            // getStartAndEndDatesDefault()
        }
    }, [data])

    useEffect(() => {
        if (dateRange?.startDate && dateRange?.endDate && moment(dateRange.endDate).format() !== "Invalid date" ) {
            calcBooking(dateRange.startDate, dateRange.endDate);
        }
    }, [dateRange])
    
    
    // useEffect(() => {
    //     if (window.innerWidth >= 767 ) {
    //         console.log("yes");
    //         setMobileView(false)
    //     }
    //     console.log(window.innerWidth);
    // }, [window.innerWidth])
    
    
    
  return (
    <>
      <ConfirmPayment getUnitData={getUnitData}/>
        <>
        <div className='container'>
        {data?.unit ? <>
            {!mobileView ? <>
                <section id='image-unit'>
                <PictureSlider data={data}/>
            </section>
            <section id='title-unit' className='mt-3'>
                <div className='title-unit'>
                    <h3 className='m-0 mt-2 fw-semibold'>{data.unit.unitName}</h3>
                    <h6 className='code-title-unit'>({data.unit.unitCode})</h6>
                    <ul className="info-unit d-flex justify-content-start">
                        {/* <li className='ms-md-5 ms-4 text-myMediumGray'>5 <FontAwesomeIcon icon={faStar} className='star-review-unit'/></li> */}
                        <li className='ms-md-5 ms-4'><FontAwesomeIcon icon={faLocationDot} className='text-myPrimary ps-1' /><span className='text-myMediumGray'>{data.unit.country}</span></li>
                        <li className='ms-md-5 ms-4 text-myMediumGray'><FontAwesomeIcon icon={faBed} className='text-myPrimary ps-1' /> عدد الغرف <span>{data.unit.bedrooms}</span></li>
                    </ul>
                </div>
            </section>
            <hr  className='line-unit'/>
            <div className='row'>
                <div className="col-md-8">
                    <section className='guarantee-unit' id='guarantee-unit'>
                        <div className='w-100 d-flex align-items-center'>
                            <h4 className='ms-3 fw-semibold text-myPrimary fs-4'>ضمان</h4>
                            <img className='img-guarantee-unit' src={logo}/>
                        </div>
                        <p className='mb-3'>نضمن لك صحة المعلومات ونظافة المكان</p>
                        <p>نضمــن لـك صحة المعلومات فـي هذه الصفحـة ونضمـن لك نظافة المكان و فـي حال لـم تطابق المعلومــات 80% أو وجــدت المكــان غيــر نـظيــف نوفر لك أحد الخيارات</p>
                        <ul className='me-4'>
                            <li className='my-1'>حجز بديل بنفس المواصفات أو أفضل</li>
                            <li className='my-1'>نلغي الحجز و نسترجع المبلغ بغض النظر عن سياسة الإلغاء و الإسترجاع</li>
                        </ul>
                    </section>
                    <section className='bar-unit' id='bar-unit'>
                        <div className='d-flex justify-content-around mx-2 pb-4'>
                            <h5 className='cursor-pointer' onClick={(e) => smoothScroll(e, "#desc-unit")} >المواصفات</h5 >
                            <h5 className='cursor-pointer' onClick={(e) => smoothScroll(e, "#location-unit")} >مكاني</h5 >
                            <h5 className='cursor-pointer' onClick={(e) => smoothScroll(e, "#bookingCalender-unit")} >المواعيد</h5 >
                            <h5 className='cursor-pointer' onClick={(e) => smoothScroll(e, "#policy-cancellation-unit")} >الشروط</h5 >
                        </div>
                    </section>
                    <section id='desc-unit' className='desc-unit' >
                        <h4 className='fw-semibold'>الوصف</h4>
                        <p> {data.unit.description}</p>
                    </section>
                    <hr  className='line-unit'/>
                    <section id='services-unit' className='services-unit'>
                        <h4 className='fw-semibold'>الخدمات</h4>
                        <div className='row g-1'>
                            <ul className='col-sm-6 m-0'>
                                {showMoreServices ? servicesHtml.slice(0, Math.fround(servicesHtml.length / 2)) : servicesHtml.slice(0,4) }
                            </ul>
                            <ul className='col-sm-6 m-0'>
                                {showMoreServices ? servicesHtml.slice(Math.fround(servicesHtml.length / 2)) : servicesHtml.slice(4,8)}
                            </ul>
                        </div>
                        <div>
                            <button className='button-services' onClick={() => setShowMoreServices(!showMoreServices)}>{showMoreServices ? "اضغط هنا لأقل" : "اضغط هنا للمزيد" }</button>
                        </div>
                    </section>
                </div>
                <div className='col-md-4 d-none d-md-inline-block'>
                    <section id='booking-unit' className='booking-unit mb-5' >
                        <h5 className='text-myPrimary fw-semibold mb-4'><span>{data.unit.cost} </span>ج.م / <span>{data.unit.costInterval === "night" ? "ليلة" : "شهر"}</span></h5>
                        <div className='calender-booking'>
                            <DateRangePicker
                                isRTL={true}
                                startDate={dateRange?.startDate}
                                endDate={dateRange?.endDate}
                                startDateId='startDate'
                                endDateId='endDate'
                                onDatesChange={handleChangeDate}
                                focusedInput={focusedInput2}
                                onFocusChange={(focusedInput) => setFocusedInput2(focusedInput)}
                                startDatePlaceholderText='من'
                                endDatePlaceholderText='الي'
                                displayFormat="YYYY/MM/DD"
                                customArrowIcon={"-"}
                                isOutsideRange={isOutsideRange}     
                                isDayBlocked={isDayBlocked}
                                numberOfMonths={2}
                                hideKeyboardShortcutsPanel
                                // showClearDates
                                reopenPickerOnClearDates
                                // renderCalendarInfo={() =><> <Presets
                                // startDate={dateRange.startDate}
                                // endDate={dateRange.endDate}
                                // displayFormat={"YYYY/MM/DD"}
                                // handlePresets={(start, end) => {
                                //     setDateRange({ startDate : start, endDate : end });
                                // }}
                                // />
                                // <Button className='btn-myPrimary mb-3 ms-3' onClick={() => {dateRange.startDate && dateRange.endDate ? setFocusedInput(false) : <></>}}>تأكيد</Button>
                                // </>}
                        />
                        </div>
                        <div className='individuals-booking d-flex justify-content-between align-items-center'>
                            <div className='py-2'>
                                <h6 className='m-0'>الأفراد</h6>
                                {/* <p className=''><span>{bookingData.reqGuests}</span> فرد</p> */}
                            </div>
                            <div className='d-flex'>
                                <button className='btn-icon-guests'>
                                    <FontAwesomeIcon className='icon-booking' onClick={handleChangeMinusGuests} icon={faMinus}/>
                                </button>
                                <div className='mx-3 my-auto mx-2'>
                                    {bookingData.reqGuests}
                                </div>
                                <button className='btn-icon-guests'>
                                    <FontAwesomeIcon className='icon-booking' onClick={handleChangePlusGuests} icon={faPlus}/>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button className='booking-button' onClick={() => bookingData.reqNights && availableTime !== false ? redirectToInvoicePayment() : () => {} }>إحجز</button>
                            {availableTime === false ? <div className='myText-warning'>غير متاح هذا التاريخ</div> : <></>}
                        </div>
                        <div className='d-flex justify-content-between mx-3 align-items-center my-3 calc-booking'>
                            {/* <p><span>{bookingData.reqNights}</span> ليالي  * <span>{data.unit.cost}</span> ج.م</p>
                            <p><span>{bookingData.reqTotalCost}</span> ج.م</p> */}
                            <div>
                                {transaction?.map((trans) => <p><span>{trans.nights}</span> ليلة *<span> {data.unit.costInterval === "night" ? trans.price : (trans.price / 30.5).toFixed(2)} </span>ج.م</p> )}
                            </div>
                            <div>
                                {transaction && transaction?.map((trans) => <p><span>{data.unit.costInterval === "night" ? trans.nights * trans.price : (trans.nights * trans.price / 30.5).toFixed(2) }</span> ج.م </p>)}
                            </div>

                        </div>
                        <hr className='line-booking'/>
                        <div className='d-flex justify-content-between mx-3 align-items-center my-3'>
                            <p>الإجمالي</p>
                            <p>{bookingData.reqTotalCost}</p>
                        </div>
                        <div className='mx-3'>
                            <p className='myFs-10'><span>*</span>اي مناقشة في السعر تتم من خلال مكالمة تأكيد الحجز بعد اتمام عملية الحجز<span>*</span></p>
                            <p className='myFs-10'><span>*</span>سيتم الإتفاق على مبلغ جديه الحجز و كذلك التأمين خلال الإتصال لتأكيد الحجز<span>*</span></p>
                        </div>
                    </section>
                </div>
            </div>
            <hr  className='line-unit'/>
            <section id='bookingCalender-unit' className='bookingCalender-unit'>
                <h4 className='fw-semibold'><span>{bookingData.reqNights}</span> ليالي</h4>
                {dateRange.startDate && dateRange.endDate ? <p className='mb-4 d-block'><span>{moment(dateRange.startDate).format("LL")}</span> - <span className='w-fitContent w-100'>{moment(dateRange.endDate).format() !== "Invalid date"  ? moment(dateRange.endDate).format("LL") : <>الي</>}</span></p> : <p className='mb-4 d-block'><span>من</span> - <span className='w-fitContent w-100'>الي</span></p>}
                <div className='ms-1 d-none d-md-inline-block' >
                <DayPickerRangeController
                    isRTL={true}
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onDatesChange={(e) => handleChangeDate(e)}
                    onFocusChange={handleFocusChange}
                    focusedInput={focusedInput}
                    isOutsideRange={isOutsideRange}
                    isDayBlocked={isDayBlocked}     
                    numberOfMonths={2}
                    daySize={45}
                    noBorder 
                    hideKeyboardShortcutsPanel
                />
                </div>
                <div className='ms-1 d-inline-block d-md-none' >
                <DayPickerRangeController
                    isRTL={true}
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onDatesChange={(e) => handleChangeDate(e)}
                    onFocusChange={handleFocusChange}
                    focusedInput={focusedInput}
                    isOutsideRange={isOutsideRange}
                    isDayBlocked={isDayBlocked}     
                    numberOfMonths={1}
                    daySize={40}
                    noBorder 
                    hideKeyboardShortcutsPanel
                />
                </div>
            </section>
            <hr  className='line-unit'/>
            <section id='location-unit' className='location-unit'>
                <h4 className='fw-semibold'>المكان !</h4>
                <h5 className='mb-4'>{data.unit.country}</h5>
                <LocationMap address={data.unit.location}/>
            </section>
            <hr  className='line-unit'/>
            <section id='policy-cancellation-unit' className='policy-cancellation-unit'>
                <h4 className='mb-5'>الشروط و سياسة الإلغاء</h4>
                <div className='d-md-flex justify-content-start'>
                    <div className='ms-5 policy-item-1'>
                        <h5>الشروط</h5>
                        <ul>
                            {data.unit.isCheckin || data.unit.isCheckout ? <></> : <h6 className='fw-semibold pt-2 mb-1'>لا يوجد</h6> }
                            {data.unit.isCheckin ? <li>تسجيل الوصول من 12 - 2 م</li> : <></>}
                            {data.unit.isCheckout ? <li>تسجيل المغادرة من 10 - 12 ص</li> : <></>}
                        </ul>
                    </div>
                    <hr  className='line-unit d-md-none d-inline-block'/>
                    <div className=''>
                        <h5>سياسة الإلغاء</h5>
                        <ul className='policy-item-2 '>
                            <li className='mb-1'>
                                {data.unit.cancelation ? <p>قابل للأسترداد بحد اقصي يومين من الحجز</p> : <p>هذا الحجز غير قابل للاسترداد. </p>}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id='footer-booking-unit-mobile'>
                <div className="d-md-none d-flex justify-content-between align-items-center px-3 footer-booking-unit-mobile">
                    <div className=''>
                        <h5 className='text-myPrimary fw-semibold'><span>{data.unit.cost}</span> ج.م / ليلة</h5>
                    </div>
                    <div className='d-flex align-items-center '>
                        <div>
                            <button className='booking-button-mobile' onClick={() => setMobileView(true)}>إحجز</button>
                        </div>
                    </div>

                </div>
            </section>
            </>
            : <>
                <MobileBooking 
                    bookingData={bookingData}
                    data={data} 
                    dateRange={dateRange} 
                    handleChangeDate={handleChangeDate}
                    handleChangePlusGuests={handleChangePlusGuests}
                    handleChangeMinusGuests={handleChangeMinusGuests}
                    handleFocusChange={handleFocusChange}
                    isOutsideRange={isOutsideRange}
                    isDayBlocked={isDayBlocked}
                    getStartAndEndDatesDefault={getStartAndEndDatesDefault}
                    focusedInput2={focusedInput2}
                    setFocusedInput2={setFocusedInput2}
                    setMobileView={setMobileView}
                    setOldClientName={setOldClientName}
                    oldClientName={oldClientName}
                    transaction={transaction}
                />
            </> }
    </> : <>
        <div>
            <section >
                {/* Desktop */}
                <div className='image-unit d-none d-md-flex'>
                    <ContentLoader 
                        rtl
                        speed={2}
                        width={"100%"}
                        height={"432px"}
                        // viewBox="0 0 476 276"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        radius={40}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="50%" height="100%" />
                        <rect x="51%" y="0" rx="5" ry="5" width="24%" height="49%" />
                        <rect x="51%" y="51%" rx="5" ry="5" width="24%" height="49%" />
                        <rect x="76%" y="0" rx="5" ry="5" width="24%" height="49%" />
                        <rect x="76%" y="51%" rx="5" ry="5" width="24%" height="49%" />
                    </ContentLoader>
                </div>
                {/* Mobile */}
                <div className="d-flex d-md-none text-center ">
                    <ContentLoader 
                        rtl
                        speed={2}
                        width={"100%"}
                        height={"432px"}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >

                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                    </ContentLoader>
                </div>
                
            </section>
            <section>
                <div className='title-unit'>
                    <ContentLoader 
                            rtl
                            speed={2}
                            width={"100%"}
                            height={"600px"}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="0" y="20" rx="5" ry="5" width="100%" height="40px" />
                            <rect x="0" y="80" rx="5" ry="5" width="40%" height="30px" />
                            <rect x="0" y="130" rx="5" ry="5" width="60%" height="80px" />
                            <rect x="0" y="230" rx="5" ry="5" width="60%" height="80px" />
                        </ContentLoader>
                </div>
            </section>
        </div>
        </>}
        </div>

        <div className='secure-mobile-view'>
            <div className='contacts-popup'>
                <ContactsPopup bookingData={bookingData} togglePopupContact={togglePopupContact} setOldClientName={setOldClientName} oldClientName={oldClientName}/>   
            </div> 
        </div>
        
        
        
    </>
    
    {!mobileView ?<div className='parent-preview-unit-footer'> <div className='container preview-unit-footer mb-md-0 '>
        <Footer/>
    </div></div> : <></>}
    {/* {isSigninPopupOpen ? <SigninPopup/> : <></>} */}
    </>
    
  )
}
