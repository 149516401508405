import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ErrorPaidMessagePopup() {
    const {togglePopUp} = useApi();

    const [bookingData, setBookingData] = useState(null);
    const [unitData, setUnitData] = useState(null);
    const [transaction, setTransaction] = useState(null);

    function popupClose(e) {
        if (e.target.classList.contains("containerBox")) {
            togglePopUp("failure-paid-message");
        }    
      }

    useEffect(() => {
        setBookingData(JSON.parse(sessionStorage.getItem("bookingData")));
        setUnitData(JSON.parse(sessionStorage.getItem("unitData")));
        setTransaction(JSON.parse(sessionStorage.getItem("transaction")));
        // togglePopUp("failure-paid-message");
    }, [])
    
  return (
    <>
    <section className='failure-paid-message'>
        <div className='containerBox ' onClick={(e) => popupClose(e)}>
            <div className='lightContainer' >
                <div className='failure-paid-message-container popup-container'>
                        {unitData && 
                            <div>
                                <div className='text-center mb-3'>
                                    <FontAwesomeIcon className='failure-icon' icon={faCircleXmark} />
                                </div>
                                <h5 className='mb-3 text-center'>لقد حدث خطأ اثناء عملية الدفع يرجى اعادة المحاولة لاحقًا! 😥</h5>
                            </div>
                        }
                </div>
            </div>
        </div>
    </section>

    </>
  )
}
