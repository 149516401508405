import React, { useEffect, useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { Bounce, toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

export default function LoginForm({redirectLoginSuccess}) {
    
    const { responseApi, getSlug, token, setToken } = useApi();

    const [response, setResponse] = useState({})

    const location = useLocation()

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const resData = await handleApiResponse(data);
        // console.log(resData);
    }
    function resetInputs() {
        reset(
            {
                email : "",
                password : ""
            }
        )
    }

    async function handleApiResponse(data) {
        console.log(data);
        setResponse(null)
        const result = await responseApi("rentingUser/login", "patch", {...data, slug : getSlug()})
        console.log(result);
        setResponse(result)
        if (result?.message === "success") {
            localStorage.setItem("token", result.token)
            setToken(result.token)
            resetInputs()
            redirectLoginSuccess()
            
        } else if (result?.message === "The password wrong. Please check the data") {
            toast.error("يوجد شئ خطأ بكلمة السر", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }else if (result?.message === "The renting user does not exist. Please check the data") {
            toast.error("لا يوجد حساب لهذا البريد الإلكتروني", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else if (result?.message === "The required company account does not exist or not active.") {
            toast.error("حساب الشركة المطلوب غير موجود أو غير مفعل.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
        
    }

    return (
        <>
        <div className='login'>
            <div className='login-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-5 text-center'>
                        <h3 className='login-title'>تسجيل الدخول</h3>
                    </div>
                    <ul>
                        <li class="mb-2">
                            <input className='primary-input' 
                                type="text" 
                                name="email" 
                                placeholder="البريد الالكتروني"
                                {...register("email", { required: true })}
                            />
                            {errors.email && errors.email.type === "required" && (
                            <div className='text-danger'>البريد الالكتروني مطلوب</div>
                            )}
                        </li>
                        <li class="mb-2">
                            <input 
                                className='primary-input' 
                                type="password" 
                                name="password" 
                                placeholder="كلمة السر" 
                                {...register("password", { required: true})}
                            />
                            {errors.password && errors.password.type === "required" && (
                            <div className='text-danger'>كلمة السر مطلوبة</div>
                            )}
                        </li>
                    </ul>
                    <div><Link to={"/forget-password"}> <p className='text-start text-myPrimary'>نسيت كلمة السر ؟</p></Link></div>
                    <div>
                        <button className='login-btn' type="submit" disabled={response?.message || response ? false : true}>{response?.message || response ? "تسجيل الدخول" : <Spinner size={"sm"} animation="border" variant="light" /> }</button>
                        <p className='mt-3 fs-6'>ليس لديك حساب؟<Link to={"/register"}><span className='ms-1 text-myPrimary'> تسجيل حساب جديد.</span></Link></p>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}